import { StampedModel, ResourceFactory } from '../services/';

class Devicetype extends StampedModel {
  public name: string = '';
  public description: string = '';
  public devices_count: number = 0;
  public type: string = '';
}

let DevicetypeFactory = ResourceFactory.create<Devicetype>(Devicetype, 'devicetype');

export { Devicetype, DevicetypeFactory };