import * as React from 'react';
import { Country, CountryFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Image } from '@independent-software/typeui/controls/Image';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Flag } from '../../controls/Flag/Flag';

interface IProps extends IListProps {
  /**
   * Event is fired when an item is clicked.
   */
  onClick?: (country: Country) => void;
}

interface IState extends IListState<Country> {
}

class ListCountries extends List<Country, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, CountryFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state
    };
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeFilterNonempty = (value: boolean) => {
    this.setFilter('projects', 'neq', value ? 0 : null);
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search records">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="">
          <Checkbox label="Only countries with projects" name='nonempty' type="toggle" checked={this.getFilter('projects', 'neq') != null} onChange={this.handleChangeFilterNonempty}/>
        </Form.Uncontrolled>        
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={4} label="Name" order="name" dir="asc">{(item:Country) => <span><Flag code={item.code} name={item.name}/> {item.name}</span>}</DataTable.Column>
          <DataTable.Column weight={1} label="Projects" order="projects_count" dir="desc" align="right">{(item:Country) => item.projects_count == 0 ? '-' : item.projects_count}</DataTable.Column>
          <DataTable.Column weight={1} label="Code" order="code" dir="asc" align="right">{(item:Country) => item.code}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListCountries };
