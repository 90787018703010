import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'

interface IProps {
  className?: string;
  online: boolean;
  lastseen: string;
}

class OnlineBase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className} title={p.lastseen}>
      </div>
    );
  }
}

const Online = styled(OnlineBase)`
  display: inline-block;
  margin-bottom: 2px;
  margin-left: 2px;
  margin-right: 8px;
  width: 7px;
  height: 7px;
  border-radius: 3.5px;
  background: ${p => p.online ? '#76FD86' : '#FDAA87'};
`;

export { Online };
