import * as React from 'react';
import { Section, Viewer } from '../../modules';
import { Devicetype, DevicetypeFactory, Device } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Markdown} from '@independent-software/typeui/formatters/Markdown';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { ListDevices } from '../Device/ListDevices';
import { Label } from '@independent-software/typeui/controls/Label';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Table } from '@independent-software/typeui/controls/Table';

class ViewDevicetype extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, {}> {
  private canEdit = (devicetype: Devicetype): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_devicetypes');
  }

  private handleEdit = () => {
    this.props.history.push(`/devicetypes/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Device type deleted");
    this.props.history.goBack();
  }    

  private handleDeviceClick = (item:Device) => {
    this.props.history.push(`/devices/${item.id}`);
  }     

  render() {
    let p = this.props;
    return (
      <Viewer<Devicetype> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={DevicetypeFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(devicetype: Devicetype) => 
          <React.Fragment>
            <Section dark padded>
              <Header size="h3">{devicetype.name}</Header>
            </Section>
            <Tabs underlined>
              <Tabs.Pane label="Device type data">
                <Section padded>
                  <Flex.Columns count={2}>
                    <div>
                      <Segment secondary raised attached="top">
                        <b>Device type data</b>
                      </Segment>
                      <Segment raised attached="bottom">
                        <Table striped transparent>
                          <tbody>
                            <tr>
                              <td>Type</td>
                              <td>{devicetype.type}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Segment>
                    </div>
                    <div>
                      <Segment secondary raised attached="top">
                        <b>Description</b>
                      </Segment>
                      <Segment raised attached="bottom">
                        {devicetype.description && <Markdown source={devicetype.description}/>}
                        {!devicetype.description && <p>There is no device type description.</p>}
                      </Segment>
                    </div>
                  </Flex.Columns>

                  
                  
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label={<span>Devices <Label>{devicetype.devices_count}</Label></span>}>
                <ListDevices auth={p.auth} name="devicetype_devices" devicetypeId={devicetype.id} onClick={this.handleDeviceClick}/>
              </Tabs.Pane>                
            </Tabs>
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewDevicetype };
