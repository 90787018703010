import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import * as L from 'leaflet';
import axios from 'axios';

import { Stats, Section, Viewer } from '../../modules';
import { Project, ProjectFactory, Device, DeviceFactory } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Label } from '@independent-software/typeui/controls/Label';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Markdown} from '@independent-software/typeui/formatters/Markdown';

import { ListDevices } from '../Device';
import { Gps } from '../../formatters';
import { Query, ValueStore } from '../../services';
import { DevicesMap } from './DevicesMap';
import { TIMEZONES } from '../../services/Timezone';
import { Table } from '@independent-software/typeui/controls/Table';
import { App } from '../../App';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Flag } from '../../controls/Flag/Flag';

interface IState {
  devices: Device[]
  stats: {
    devices: number;
    devices_offline: number;
    capacity: number;
    storage: number;
    storage_percentage: number;
  }
}

class ViewProject extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<{id:string}>) {
    super(props);
    this.state = {
      devices: [],
      stats: null
    }
  }

  componentDidMount() {
    // Load all devices belonging to this project, in order to put them on a map.
    let q: Query = new Query('eui');
    q.setFilter("project", 'eq', parseInt(this.props.match.params.id));
    DeviceFactory.getSome(this.props.auth, 0, 999, q).then((data) => {
      this.setState({
        devices: data.items
      })
    });

    // Load project statistics
    axios.get(`${App.apiURL}project/stats/${parseInt(this.props.match.params.id)}`)
    .then(response => {
      this.setState({
        stats: response.data
      });
    })
    .catch(error => { });
  }

  private canEdit = (project: Project): boolean => {
    return this.props.auth && ((project.created_by != null && project.created_by.id == this.props.auth.id) 
           || this.props.auth.hasRight('can_edit_projects'));
  }  

  private handleEdit = () => {
    this.props.history.push(`/projects/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Project deleted");
    this.props.history.goBack();       
  }  

  private handleDeviceClick = (item:Device) => {
    this.props.history.push(`/devices/${item.id}`);
  }      

  private handleAddDevice = () => {
    this.props.history.push(`/devices/add/${parseInt(this.props.match.params.id)}`);
  }

  private getTimezone = (timezoneID: string) => {
    let timezone = TIMEZONES.find(tz => tz.id == timezoneID);
    // If timezone does not exist, use UTC.
    if(!timezone) return TIMEZONES[14];
    return timezone;
  }

  private handleTabChange = (index: number) => {
    ValueStore.set("view_project", index);
  }  

  render() {
    let p = this.props;
    return (
      <Viewer<Project> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={ProjectFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        controls={(project: Project) => {
          return this.props.auth && (project.created_by.id == this.props.auth.id 
          || this.props.auth.hasRight('can_edit_devices')) && <Button primary onClick={this.handleAddDevice}><Icon name="plus"/> Add device</Button>}}
        content={(project: Project) => {
          let lat = parseFloat(project.latitude); 
          let lng = parseFloat(project.longitude); 
          let latlng = (isNaN(lat) || isNaN(lng)) ? null : L.latLng(lat, lng);
          let timezone = this.getTimezone(project.timezone);

          return (<React.Fragment>
            <Section dark padded>
              <Header size="h3">{project.name}</Header>
            </Section>

            <Tabs underlined nohiddenrender active={ValueStore.get('view_project', 0)} onTabChange={this.handleTabChange}>
              <Tabs.Pane label="Project data">
                <Section padded>

                  {this.state.stats &&
                  <React.Fragment>
                    <Stats>
                      <Stats.Title>Devices</Stats.Title>
                      <Stats.Center>
                        <Stats.BigNumber><Number value={this.state.stats.devices} decimals={0}/></Stats.BigNumber>
                        devices
                      </Stats.Center>
                      <Stats.Bottom alert={this.state.stats.devices_offline > 0}>
                        <Number value={this.state.stats.devices_offline} decimals={0}/> currently offline
                      </Stats.Bottom>
                    </Stats>            
                    <Stats>
                      <Stats.Title>Beneficiaries</Stats.Title>
                      <Stats.Center>
                        <Stats.BigNumber><Number value={project.beneficiaries} decimals={0}/></Stats.BigNumber>
                      </Stats.Center>
                      <Stats.Bottom>
                        &nbsp;
                      </Stats.Bottom>
                    </Stats>            
                    <Stats>
                      <Stats.Title>Water storage</Stats.Title>
                      <Stats.Center>
                        <Stats.BigNumber><Number value={this.state.stats.storage} decimals={2}/></Stats.BigNumber>
                        m<sup>3</sup>
                      </Stats.Center>
                      <Stats.Bottom alert={this.state.stats.storage_percentage < 10}>
                        {this.state.stats.storage_percentage}% of capacity (<Number value={this.state.stats.capacity} decimals={2}/> m<sup>3</sup>)
                      </Stats.Bottom>
                    </Stats>            
                  </React.Fragment>}

                  <Divider section>Properties</Divider>

                  <Flex.Columns count={2}>
                    <div>
                      <Segment secondary raised attached="top">
                        <b>Data</b>
                      </Segment>
                      <Segment raised attached="bottom">
                        <Table striped transparent>
                          <tbody>
                            <tr>
                              <td style={{width: '25%'}}>Country</td>
                              <td><Flag code={project.country.code} name={project.country.name}/> {project.country.name}</td>
                            </tr>
                            <tr>
                              <td>Visibility</td>
                              <td>{project.public 
                                ? <Flex.Quick><Icon color='darkgreen' name='unlock'></Icon> public</Flex.Quick> 
                                : <Flex.Quick><Icon color='darkred' name='lock'></Icon> private</Flex.Quick>}
                              </td>
                            </tr>
                            <tr>
                              <td>Timezone</td>
                              <td><span title={timezone.name}>{timezone.id}</span></td>
                            </tr>
                            <tr>
                              <td>Partner</td>
                              <td>{project.partner}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Segment>
                    </div>
                    <div>
                      <Segment secondary raised attached="top">
                        <b>Project description</b>
                      </Segment>
                      <Segment raised attached="bottom">
                        {project.description && <Markdown source={project.description}/>}
                        {!project.description && <p>There is no project description.</p>}
                      </Segment>
                    </div>
                  </Flex.Columns>
                </Section>
              </Tabs.Pane>
              <Tabs.Pane label="Map">
                <DevicesMap point={latlng} devices={this.state.devices}/>
                <GpsOverlay>
                  <Gps latitude={project.latitude} longitude={project.longitude}/>
                </GpsOverlay>
              </Tabs.Pane>
              <Tabs.Pane label={<span>Devices <Label>{project.devices_count}</Label></span>}>
                <ListDevices auth={p.auth} name="project_devices" projectId={project.id} onClick={this.handleDeviceClick}/>
              </Tabs.Pane>                
            </Tabs>  
          </React.Fragment>) }}>
      </Viewer>
    );
  }
}

// Overlays content on top of map
const GpsOverlay = styled('div')`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 999;
`;


export { ViewProject };
