import { IRoutes, IMenu } from '../Types';

import { Dashboard } from '../components/Dashboard';
import { Account } from '../components/Account';
import { LogList } from '../components/Log';

import { UserList, ViewUser, AddUser, EditUser } from '../components/User';
import { ProfileList, ViewProfile, AddProfile, EditProfile  } from '../components/Profile';
import { CountryList, ViewCountry, AddCountry, EditCountry } from '../components/Country';
import { ProjectList, AddProject, EditProject, ViewProject } from '../components/Project';
import { DevicetypeList, AddDevicetype, EditDevicetype, ViewDevicetype } from '../components/Devicetype';
import { DeviceList, AddDevice, ViewDevice, EditDevice } from '../components/Device/';
import { EditReading } from '../components/Reading';
import { ResetPassword } from '../components/ResetPassword';

/*
 * The following table contains all the routes available in the web application.
 * Each route has a unique key, which can be used to refer to it when building menus.
 * 
 * Each route is defined with:
 * - path:      URL for the route (after the hash)
 * - requireUser: true, right:    Optional. Users must be logged-in and have this permission to access the route.
 * - title:     Title that appears at the top of the screen.
 * - component: Route component to load.
 */

let routes: IRoutes = {
  dashboard:         { right: false, path: "/", title: "Dashboard", component: Dashboard },
  
  account:           { right: true, path: "/account", title: "Account", component: Account },
  reset:             { right: false, path: "/reset", title: "Reset Password", component: ResetPassword },
  users_list:        { right: 'can_view_users', path: "/users",  title: "Users",  component: UserList  },
  users_add:         { right: 'can_edit_users', path: "/users/add", title: "Add User", component: AddUser },
  users_edit:        { right: 'can_edit_users', path: "/users/edit/:id", title: "Edit user", component: EditUser },
  users_view:        { right: 'can_view_users', path: "/users/:id", title: "User", component: ViewUser },
  profiles_list:     { right: 'can_view_profiles', path: "/profiles",  title: "Profiles",  component: ProfileList },
  profiles_add:      { right: 'can_edit_profiles', path: "/profiles/add", title: "Add profile", component: AddProfile },
  profiles_edit:     { right: 'can_edit_profiles', path: "/profiles/edit/:id", title: "Edit profile", component: EditProfile },
  profiles_view:     { right: 'can_view_profiles', path: "/profiles/:id", title: "Profile", component: ViewProfile },
  logs_list:         { right: 'can_view_logs', path: "/logs", title: "Logs", component: LogList },

  devicetypes_list:  { right: 'can_view_devicetypes', path: '/devicetypes', title: "Device types", component: DevicetypeList },
  devicetypes_add:   { right: 'can_edit_devicetypes', path: "/devicetypes/add", title: "Add device type", component: AddDevicetype },
  devicetypes_edit:  { right: 'can_edit_devicetypes', path: "/devicetypes/edit/:id", title: "Edit device type", component: EditDevicetype },
  devicetypes_view:  { right: 'can_edit_devicetypes', path: "/devicetypes/:id", title: "Device type", component: ViewDevicetype },

  countries_list:    { right: 'can_view_countries', path: '/countries', title: "Countries", component: CountryList },
  countries_add:     { right: 'can_edit_countries', path: "/countries/add", title: "Add country", component: AddCountry },
  countries_edit:    { right: 'can_edit_countries', path: "/countries/edit/:id", title: "Edit country", component: EditCountry },
  countries_view:    { right: 'can_edit_countries', path: "/countries/:id", title: "Country", component: ViewCountry },

  projects_list:     { right: false, path: '/projects', title: "Projects", component: ProjectList },
  projects_add:      { right: true,  path: "/projects/add", title: "Add project", component: AddProject },
  projects_edit:     { right: true,  path: "/projects/edit/:id", title: "Edit project", component: EditProject },
  projects_view:     { right: false, path: "/projects/:id", title: "Project", component: ViewProject },

  devices_list:      { right: false, path: '/devices', title: "Devices", component: DeviceList },
  devices_add:       { right: true,  path: '/devices/add/:id', title: "Add device", component: AddDevice },
  devices_edit:      { right: true,  path: '/devices/edit/:id', title: "Edit device", component: EditDevice },
  devices_view:      { right: false, path: "/devices/:id", title: "Device", component: ViewDevice },

  readings_edit:     { right: true,  path: '/readings/edit/:id', title: "Edit reading", component: EditReading }
};

/*
 * The application's main menu consists of groups and items.
 * A group has a key (its name), and an object containing items.
 * Each object has:
 * - A key (the item label)
 * - A reference to a route to load.
 * Menu items are only rendered if route conditions are satisfied.
 */
let menu: IMenu = {
  "Data": {
    "Dashboard": routes['dashboard'],
    "Projects": routes['projects_list'],
    "Devices": routes['devices_list']
  },
  "Users": {
    "Users": routes['users_list'],
    "Profiles": routes['profiles_list'],
    "Log": routes['logs_list']
  },
  "Administration": {
    "Device types": routes['devicetypes_list'],
    "Countries": routes['countries_list']
  }
} 

export { routes, menu };