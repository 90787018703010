import * as React from 'react';
import * as L from 'leaflet';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';
import { MAP } from '../../config/Config';

interface IMapProps {
  className?: string;
  children?: React.ReactNode;
  point?: L.LatLng;
  zoom?: number;
  onClick?: (latlng: L.LatLng) => void;
}

class MapBase extends React.Component<IMapProps, {}> {
  private mapElement: HTMLDivElement;
  private map: L.Map;
  private marker: L.CircleMarker;

  componentDidMount() {
    this.map = L.map(this.mapElement, {
      center: [0, 0],
      zoom: this.props.zoom ? this.props.zoom : 3,
      zoomControl: false,
      layers: [
        L.tileLayer(MAP.url, {
          attribution: MAP.attribution
        }),
      ]
    });

    // Add zoom control at bottom right:
    L.control.zoom({ position: 'bottomright' }).addTo(this.map);    
    
    this.createMarker();
    this.setMarker(this.props.point);
    this.map.on('click', (e: L.LeafletMouseEvent) => this.handleMapClick(e));
  }

  componentDidUpdate(prevProps: IMapProps) {
    // If the point coordinate has changed, then move the marker (and the map view)
    // to the new coordinate. Use a slight timeout for the change to take effect.
    if(this.props.point !== prevProps.point) {
      setTimeout(() => {
        this.setMarker(this.props.point);
        this.map.setView(this.props.point, this.map.getZoom());
      }, 100);
    }
  }

  private handleMapClick = (e: L.LeafletMouseEvent) => {
    if(this.props.onClick) this.props.onClick(e.latlng);
  }

  private createMarker = () => {
    let circleOptions = {
      radius: 5, 
      fillColor: 'slateblue', 
      color: 'darkblue', 
      weight: 1
    };
    this.marker = L.circleMarker([0,0], circleOptions);
  }

  private setMarker = (position: L.LatLng) => {
    if(position) {
      this.marker.setLatLng(position).addTo(this.map);
      this.map.panTo(position);
    } else {
      this.marker.remove();
    }
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.mapElement = el}>{p.children}</div>
    );
  }
}

const MapStyled = styled(MapBase)`
  width: 100%;
  height: 100%;
  min-height: 500px;
  border-radius: ${p => p.theme.radius}px;
`;

class Map extends React.Component<IMapProps, {}> {
  public static displayName = "Map";

  render() {
    let p = this.props;
    return (
      <MapStyled {...p}></MapStyled>
    )
  }
}

export { Map };