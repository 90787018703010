import * as React from 'react';
import { Container, Content, BottomBar, Section } from '../../modules';
import { DeviceForm } from './DeviceForm';
import { DeviceFactory, Device, Project, ProjectFactory } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';

type TStep = 'ready' | 'saving' | 'saveError';

interface IState {
  device: Device;
  latitude: string;
  longitude: string;
  isValid: boolean;
  step: TStep;
  error: any;
  dirty?: boolean;
}

interface MatchParams {
  id: string;
}

class AddDevice extends React.Component<IAuthProps & RouteComponentProps<MatchParams>, IState> {
  private device: Device;

  constructor(props: IAuthProps & RouteComponentProps<MatchParams>) {
    super(props);
    this.device = DeviceFactory.create();
    this.device.project = { id: parseInt(props.match.params.id), name: '' };
    this.state = {
      step: 'ready',
      isValid: false,
      error: null,
      device: this.device,
      latitude: null,
      longitude: null
    };

    // Retrieve parent Project for location and project name,
    // and add this information to the Device record:
    ProjectFactory.get(props.auth, parseInt(props.match.params.id))
    .then((item) => {
      this.setState({
        latitude: item.latitude,
        longitude: item.longitude
      });
    });
  }

  handleChange = (device: Device, forceupdate: boolean) => {
    this.device = Object.assign(this.device, device);
    if(forceupdate) { 
      this.setState({ device: this.device });  
    }
  }

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  handleSubmit = () => {
    this.setState({ device: this.device, dirty: true });
    if(!this.state.isValid) return;    

    this.setState({error: null, step: 'saving'});
    this.device.$save(this.props.auth)
      .then(res => {
        ToastService.toast("Device created");
        this.props.history.goBack();
      })
      .catch(error => {
        this.setState({
          step: 'saveError',
          error: error
        })
      });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {this.state.step == 'saving' && <Loader></Loader>}
        <React.Fragment>
          <Content>
            <Section padded>
              <DeviceForm auth={p.auth} dirty={this.state.dirty} data={this.state.device} latitude={this.state.latitude} longitude={this.state.longitude} onChange={this.handleChange} onValidate={this.handleValidate}/>
            </Section>
          </Content>
          <BottomBar>
            <Button primary onClick={this.handleSubmit}><Icon name="save"/> Save</Button>
          </BottomBar>
        </React.Fragment>
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave} onRetry={this.handleSubmit}/>
      </Container>
    );
  }
}

export { AddDevice };
