type TPointIndex = 'v' | 'r' | 'q' | 't' | 'p';

interface IValueType {
  id:    TPointIndex,
  name:  string;
  unit:  string;
  color: string;
}

const ValueTypes: IValueType[] = [
  { id: 'r', name: "Results",     unit: "m3",  color: 'SteelBlue' },
  { id: 'v', name: "Values",      unit: "cm",  color: 'DarkBlue' },
  { id: 'q', name: "Quality",     unit: "",    color: 'Gold' },
  { id: 't', name: "Temperature", unit: "C",   color: 'Red' },
  { id: 'p', name: "Percentage",  unit: "%",   color: 'DarkGray' }
];

export { IValueType, ValueTypes }