import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Devicetype } from '../../resource/';
import { IAuthProps } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Segment } from '@independent-software/typeui/controls/Segment';


interface IProps {
  /** Initial form data. */
  data: Devicetype;
  /** Called whenever form changes. */
  onChange: (data: Devicetype, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Devicetype;
}

class DevicetypeForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  private handleRadioChange = (type:string) => {
    this.state.data.type = type;
    this.props.onChange(this.state.data, true);
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
          <Form.Field 
            required={{message: "Device type name is required"}}
            minLength={{length: 3, message: "Device type name must be at least 3 characters."}}
            maxLength={{length: 255, message: "Device type name may not be longer than 255 characters."}}
            width={1} label="Device type name"
            name="name" 
            value={this.state.data.name}
            control={(<Input type="text" placeholder="Device type name" fluid/>)}
            hint={(<React.Fragment>Please enter a device type name, e.g. <code>TekElek Tek766</code>.</React.Fragment>)}/>
          <Form.Field 
            width={1} label="Description"
            name="description" 
            value={this.state.data.description}
            control={(<MarkdownTextarea rows={10} placeholder="Description" fluid/>)}/>

          <Segment secondary attached="top">
            Measurement type
          </Segment>
          <Segment attached="bottom">
            <div>
              <Checkbox radio type="circle" name='type' value='level' label="Water level" checked={this.state.data.type == 'level'} onChange={() => this.handleRadioChange('level')} />
              <Hint>A water level sensor measures the distance of the water surface from the top of the tank. When provided with the tank dimensions, it can calculate the volume of water present in the tank.</Hint>
            </div>
            <div>
              <Checkbox radio type="circle" name='type' value='flow' label="Flow" checked={this.state.data.type == 'flow'} onChange={() => this.handleRadioChange('flow')} />
              <Hint>A flow sensor measures the volume of water passing through it.</Hint>
            </div>
          </Segment>

      </Form>
    )
  }
}

const Hint = styled('div')`
  font-size: 80%;
  line-height: 1.4em;
  color: #aaa;
  max-width: 400px;
  margin-bottom: 8px;
`

export { DevicetypeForm };
