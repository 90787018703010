import { css, createGlobalStyle } from 'styled-components'

const base = css`
  table.popup td {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  table.popup td:first-child {
    padding-right: 15px;
    color: #888;
  }

  /* The cluster class is used by leaflet.markercluster. */
  .cluster {
    position: relative;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: rgba(148, 214, 105, 0.8);
    text-align: center;
    padding-top: 7px;
    font-weight: bold;
    z-index: 10;
  }
  .cluster::before {
    content: '';
    position: absolute;
    width: 32px;
    height: 32px;
    border: solid 4px rgb(148, 214, 105, 0.3);
    border-radius: 20px;
    left: -4px;
    top: -4px;
    z-index: -1;
  }
`;

const LeafletCustom = createGlobalStyle`
  ${base}
`;

export { LeafletCustom };
