import * as React from 'react';
import { Container, Content, BottomBar } from '../../modules';
import { Country } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListCountries } from './ListCountries';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class CountryList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
  }

  // Go to add country view.
  handleAdd = () => {
    this.props.history.push('/countries/add');
  }  

  // Go to view country view.
  handleClick = (item: Country) => {
    this.props.history.push(`/countries/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListCountries auth={p.auth} name="countries" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth && p.auth.hasRight('can_edit_countries') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> Add</Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { CountryList };
