import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

import { Device, DeviceFactory, Devicetype, Project, ProjectFactory, DevicetypeFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter, Query } from '../../services/';
import { IconBar, Export } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Eui, NA } from '../../formatters';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Online } from '../../controls/Online';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { BatteryLevel } from '../../controls/BatteryLevel';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Header } from '@independent-software/typeui/controls/Header';
import { SparkChart } from './SparkChart';

interface IProps extends IListProps {
  /**
   * If set, list is prefiltered by a project and
   * project column and filter are hidden.
   */
  projectId?: number;  
  /**
   * If set, list is prefiltered by a devicetype and
   * devicetype column and filter are hidden.
   */
  devicetypeId?: number;    
  /**
   * Event is fired when an item is clicked.
   */
  onClick?: (devicetype: Device) => void;
}

interface IState extends IListState<Device> {
  /**
   * Data for projects dropdown
   */
  projects: Project[];  
  /**
   * Data for devicetypes dropdown
   */  
  devicetypes: Devicetype[];

  showProjects: boolean;
  showDevicetypes: boolean;
  showBatterylevels: boolean;
  showCharts: boolean;
}

class ListDevices extends List<Device, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, DeviceFactory, 'eui', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      projects: [],
      devicetypes: [],
      showProjects: false,
      showDevicetypes: false,
      showBatterylevels: false,
      showCharts: true
    };

    // The project stored in the Query is an ID, not a Project.
    // Is the list prefiltered by a project? Then set a filter.    
    if(this.props.projectId) {
      this.setFilter('project', 'eq', { id: this.props.projectId }, true);
    }

    // The devicetype stored in the Query is an ID, not a Devicetype.
    // Is the list prefiltered by a devicetype? Then set a filter.
    if(this.props.devicetypeId) {
      this.setFilter('devicetype', 'eq', { id: this.props.devicetypeId }, true);
    }
  }

  componentDidMount() {
    if(!this.props.projectId) this.handleProjectSearch();
    if(!this.props.devicetypeId) this.handleDevicetypeSearch();    
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeProject = (value: Project) => {
    this.setFilter('project', 'eq', value);
  }

  handleProjectSearch = (q?:string) => {
    // Retrieve a list of projects:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    ProjectFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ projects: res.items }));
  }  

  handleChangeDevicetype = (value: Devicetype) => {
    this.setFilter('devicetype', 'eq', value);
  }

  handleDevicetypeSearch = (q?:string) => {
    // Retrieve a list of devicetypes:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    DevicetypeFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ devicetypes: res.items }));
  }    

  handleChangeShowProjects = (value: boolean) => {
    this.setState({ showProjects: value });
  }

  handleChangeShowDevicetypes = (value: boolean) => {
    this.setState({ showDevicetypes: value });
  }

  handleChangeShowBatterylevels = (value: boolean) => {
    this.setState({ showBatterylevels: value });
  }

  handleChangeShowCharts = (value: boolean) => {
    this.setState({ showCharts: value });
  }
  
  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Header size="h3">Display</Header>
        {!p.projectId &&<Checkbox checked={this.state.showProjects} type="toggle" onChange={this.handleChangeShowProjects} label="Show projects"/>}
        {!p.devicetypeId && <Checkbox checked={this.state.showDevicetypes} type="toggle" onChange={this.handleChangeShowDevicetypes} label="Show device types"/>}
        <Checkbox checked={this.state.showBatterylevels} type="toggle" onChange={this.handleChangeShowBatterylevels} label="Battery levels"/>
        <Checkbox checked={this.state.showCharts} type="toggle" onChange={this.handleChangeShowCharts} label="Show charts"/>
        <Header size="h3">Filter</Header>
        <Form.Uncontrolled hint="Type to search records">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.projectId && 
          <Form.Uncontrolled hint="Filter by project">
            <Dropdown onSearch={this.handleProjectSearch} name="project" fluid clearable value={this.getFilter('project', 'eq')} data={this.state.projects} placeholder="Project" label={(item:Project) => item.name} onChange={this.handleChangeProject}>
              <Dropdown.Column weight={1}>{(item:Project) => item.name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}
        {!p.devicetypeId && 
          <Form.Uncontrolled hint="Filter by device type">
            <Dropdown onSearch={this.handleDevicetypeSearch} name="devicetype" fluid clearable value={this.getFilter('devicetype', 'eq')} data={this.state.devicetypes} placeholder="Device type" label={(item:Devicetype) => item.name} onChange={this.handleChangeDevicetype}>
              <Dropdown.Column weight={1}>{(item:Devicetype) => item.name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={3} force label="">{(item:Device) => <Online online={item.isOnline()} lastseen={item.lastseen}/>}</DataTable.Column>
          <DataTable.Column weight={30} label={<span title="EUI">EUI</span>} order="eui" dir="asc">{(item:Device) => <Eui value={item.eui}/>}</DataTable.Column>
          <DataTable.Column weight={30} force label={<span title="Name">Name</span>} order="name" dir="asc">{(item:Device) => item.name}</DataTable.Column>
          {!p.projectId && this.state.showProjects && <DataTable.Column weight={20} label={<span title="Project">Project</span>}>{(item:Device) => item.project.name}</DataTable.Column>}
          {!p.devicetypeId && this.state.showDevicetypes && <DataTable.Column weight={20} label={<span title="Device type">Device type</span>}>{(item:Device) => item.devicetype.name}</DataTable.Column>}
          <DataTable.Column weight={10} label={<span title="Readings">Readings</span>} align="right">{(item:Device) => <Number value={item.readings_count} decimals={0}/>}</DataTable.Column>
          <DataTable.Column weight={10} label={<span title="Last result">Last</span>} align="right" order="value" dir="asc">{(item:Device) => item.result  ? <Number value={item.result} decimals={2}/> : <NA/>}</DataTable.Column>
          <DataTable.Column weight={10} label={<span title="%">%</span>} align="right">{(item:Device) => <Percentage value={item.result} max={item.maxResult}/>}</DataTable.Column>
          <DataTable.Column weight={10} label={<span title="24h mean">24h</span>} align="right" order="avg24h" dir="asc">{(item:Device) => <Percentage value={item.avg24h} max={item.maxResult}/>}</DataTable.Column>
          <DataTable.Column weight={10} label={<span title="30d mean">30d</span>} align="right" order="avg30d" dir="asc">{(item:Device) => <Percentage value={item.avg30d} max={item.maxResult}/>}</DataTable.Column>
          {this.state.showBatterylevels && <DataTable.Column weight={10} label={<Icon url="sprites.svg#battery-50" title="Battery level"/>} order="battery" dir="asc">{(item:Device) => item.battery  ? <BatteryLevel level={item.battery}/> : <NA/>}</DataTable.Column>}
          {this.state.showCharts && <DataTable.Column weight={30} label={<span title="This month">This month</span>}>
            {(item:Device) => <SparkChart data={(item as any).readings}/>}
          </DataTable.Column>}
          <DataTable.Column weight={10} label="" align="right">{(item:Device) => item.project.public ? <Icon name='unlock' color='darkgreen' title="public"/> : <Icon name='lock' color='darkred' title="private"/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

class Percentage extends React.Component<{ value: number, max: number }, {}> {
  render() {
    let p = this.props;
    
    if(!p.value) return <NA/>;
    let percentage = p.max == 0 ? 0 : 100 * p.value / p.max;

    return (
      <span style={{color: percentage <= 10 ? '#c00' : 'inherit'}}>
        <Number value={percentage} decimals={0}/>%
      </span>
    );
  }
}

export { ListDevices };
