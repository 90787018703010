import * as React from 'react';

import { XAxis, YAxis, ResponsiveContainer, AreaChart, Area } from 'recharts';
import { format, getUnixTime } from 'date-fns';
import { IPoint } from './DeviceChart';
import { subMonths } from 'date-fns/esm';

interface ISparkChartProps {
  data: IPoint[];
}

function toUTC(unix: number) {
  let date = new Date(unix * 1000);
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return date;
}

class SparkChart extends React.Component<ISparkChartProps, {}> {

  constructor(props: ISparkChartProps) {
    super(props);
  }

  render() {
    let p = this.props;
    
    // Calculate maximum Y-value. We'll need this
    // to set the chart's Y-domain.
    let max = Math.max.apply(Math, p.data.map(function(p) { return (p as any)['r'] }));

    let startdate = getUnixTime(subMonths(new Date(), 1));
    let enddate = getUnixTime(new Date());

    return (
      <ResponsiveContainer width="100%" height={30}>
      <AreaChart style={{cursor:'pointer'}} margin={{top:3, right:3, bottom:3, left:3}} data={p.data}>
        <Area type="monotone" dataKey="r" stroke="SteelBlue" strokeWidth={1} dot={false}/>
        <XAxis
          dataKey="d" 
          type='number' 
          scale='auto' 
          height={0.5}
          tickFormatter = {(unixTime) => format(toUTC(unixTime), 'dd-MMM-yy')}
          domain={[startdate, enddate]}
          stroke="#aaa"
          tick={false}
        />                  
        <YAxis 
          dataKey="r"
          width={0.5} 
          domain={[0, 1.1 * max]}
          stroke="#aaa" 
          tick={false} 
        />
      </AreaChart>
    </ResponsiveContainer>
    )
  }

}

export { SparkChart };
