import { StampedModel, ResourceFactory } from '../services/';

class Project extends StampedModel {
  public name: string = '';
  public partner: string = '';
  public beneficiaries: string = '';
  public latitude: string = '';
  public longitude: string = '';
  public description: string = '';
  public devices_count: number = 0;
  public public: number = 1;
  public country: { 
    id: number;
    name: string;
    code: string;
  } = null;
  public timezone: string = null;
}

let ProjectFactory = ResourceFactory.create<Project>(Project, 'project');

export { Project, ProjectFactory };