import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Container, Content, Section } from '../../modules';
import { Message } from '@independent-software/typeui/controls/Message';
import { IAuthProps } from '../../services';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Divider } from '@independent-software/typeui/controls/Divider';

class AccessDenied extends React.Component<IAuthProps, {}> {
  render() {
    return (
      <Container>
        <Content>
          <Section padded>
            <Flex>
              <Flex.Row>
                <Flex.Column>
                  <Door><use xlinkHref={"sprites.svg#door"}></use></Door>
                </Flex.Column>
                <Flex.Column width={1}>
                  <Divider hidden/>
                  <Message icon type="error">
                    <Icon name="lock" size="big"/>
                    <Message.Content>
                      <Message.Header>
                        Access denied
                      </Message.Header>
                      <p>
                        You do not have sufficient privileges to access this area of the application.
                      </p>
                    </Message.Content>
                  </Message>
                </Flex.Column>
              </Flex.Row>
            </Flex>
          </Section>
        </Content>
      </Container>
    );
  }
}

const Door = styled('svg')`
  width: 150px;
  height: 255px;
  margin-right: 30px;
`

export { AccessDenied };
