import * as React from 'react';
import { css } from 'styled-components';
import { Container, Content, BottomBar } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';
import { ListProjects } from '../Project';
import { Project, ProjectFactory } from '../../resource';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Query } from '../../services';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { ProjectsMap } from './ProjectsMap';

interface IState {
  projects: Project[];
}

class ProjectList extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      projects: []
    };
  }

  componentDidMount() {
    // Load all projects, in order to put them on a map.
    let q: Query = new Query('id');
    ProjectFactory.getSome(this.props.auth, 0, 999, q).then((data) => {
      this.setState({
        projects: data.items
      });
    });
  }  

  // Go to add project view.
  handleAdd = () => {
    this.props.history.push('/projects/add');
  }  

  // Go to view project view.
  handleClick = (item: Project) => {
    this.props.history.push(`/projects/${item.id}`);
  }    

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <Tabs underlined nohiddenrender>
            <Tabs.Pane label="Projects">
              <ListProjects auth={p.auth} name="projects" onClick={this.handleClick}/>
            </Tabs.Pane>
            <Tabs.Pane label="Map">
              <ProjectsMap projects={this.state.projects}/>
            </Tabs.Pane>
          </Tabs>
        </Content>
        {p.auth &&
          <BottomBar>
             <Button primary onClick={this.handleAdd}><Icon name="plus"/> Add project</Button>
          </BottomBar>}
      </Container>
    );
  }
}

export { ProjectList };
