import * as React from 'react';
import axios from 'axios';
import { Auth } from '../../services/Auth';
import { RouteComponentProps } from 'react-router';
import { SigninForm, ISignin } from './SigninForm';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { App } from '../../App';
import { Dialog } from '@independent-software/typeui/controls/Dialog';

interface ILoginProps {
  className?: string;
  /** Callback when signin is successful. */
  onSignin: (auth:Auth) => void;
}

interface ILoginState {
  signin: ISignin;
  isValid: boolean;
  /** Login request in progress? Will show a loader. */
  loginLoading: boolean;
  /** Reset request is progress? Will show a loader. */
  resetLoading: boolean;
}

class SignIn extends React.Component<ILoginProps, ILoginState> {
  constructor(props: ILoginProps & RouteComponentProps) {
    super(props);
    this.state = {
      signin: { email: '', password: '' },
      isValid: false,
      loginLoading: false,
      resetLoading: false
    };
  }

  handleChange = (signin: ISignin, forceupdate: boolean) => {
    this.setState({
      signin: signin
    });
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }    

  handleSubmit = () => {
    this.setState({
      loginLoading: true
    });
    setTimeout(() => {
      axios.post(`${App.apiURL}signin`, {
        email: this.state.signin.email,
        password: this.state.signin.password
      })
      .then(response => {
        let auth = new Auth(
          response.data.id,
          response.data.name,
          response.data.email,
          response.data.api_token,
          response.data.rights
        );
        ToastService.toast("Sign-in successful.");
        this.props.onSignin(auth);
      })
      .catch(error => {
        let text = '';
        if(error.response) {
          text = "Your email or password could not be verified.";
        } else if(error.request) {
          text = "No server response was received.";
        } else {
          text = "There was a problem sending your request.";
        }
        ToastService.toast(text);
        this.setState({
          loginLoading: false,
        });        
      });
    }, 500);
    // Adds a 500ms timeout to make sure that signin button
    // animation actually gets seen.    
  }

  handleForgot = () => {
    this.setState({
      resetLoading: true
    });
    setTimeout(() => {
      axios.post(`${App.apiURL}/forgot`, {
        email: this.state.signin.email
      })
      .then(response => {
        ToastService.toast("An email was sent to you, containing a secure link that allows you to choose a new password.");
      })
      .catch(error => {
        let text = '';
        if(error.response) {
          switch(error.response.status) {
            case 400: 
              text = "You did not provide an email address.";
              break;
            case 401:
              text = "Your email address was not recognized.";
              break;
            default:
              text = "There was a problem communicating with the server.";
              break;
          }
        } else if(error.request) {
          text = "No server response was received.";
        } else {
          text = "There was a problem sending your request.";
        }
        ToastService.toast(text);
      })
      .then(() => {
        this.setState({
          resetLoading: false
        })
      });
    }, 500);
    // Adds a 500ms timeout to make sure that forgot button
    // animation actually gets seen.        
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <SigninForm disabled={this.state.loginLoading || this.state.resetLoading} data={this.state.signin} onChange={this.handleChange} onValidate={this.handleValidate}/>
        <div style={{display: 'flex', 'justifyContent': 'space-between'}}>
          <Button secondary disabled={!this.state.isValid} onClick={this.handleForgot}>
            {this.state.resetLoading && <Icon loading name="circle-notch"/>}
            {!this.state.resetLoading && <span>I forgot my password</span>}
            {this.state.resetLoading && <span>&nbsp;Sending reset message...</span>}
          </Button>
          <Button primary disabled={!this.state.isValid} onClick={this.handleSubmit}>
            {this.state.loginLoading && <Icon loading name="circle-notch"/>}
            {!this.state.loginLoading && <span>Sign in</span>}
            {this.state.loginLoading && <span>&nbsp;Signing in...</span>}
          </Button>
        </div>
      </div>
    )
  }
}

export { SignIn };