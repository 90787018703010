import * as React from 'react';

import { Form } from '@independent-software/typeui/controls/Form';
import { Input } from '@independent-software/typeui/controls/Input';
import { Password } from '@independent-software/typeui/modules/Password';

interface ISignup {
  name: string;
  email: string;
  password: string;
}

interface ISignupFormProps {
  /** Initial form data. */
  data: ISignup;
  /** Called whenever form changes. */
  onChange: (data: ISignup, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** Are form controls disabled? */
  disabled?: boolean;
  dirty?: boolean;
}

interface ISignupFormState {
  /** Current fom data */
  data: ISignup;
}

class SignupForm extends React.Component<ISignupFormProps, ISignupFormState> {
  constructor(props: ISignupFormProps) {
    super(props);

    this.state = {
      data: props.data
    };    
  }
  
  render() {
    let p = this.props;
    return (
      <div>
        <Form data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
          <Form.Field 
            required={{message: "Name is required."}}
            minLength={{length: 3, message: "Name must be at least 3 characters"}}
            maxLength={{length: 30, message: "Name may not be longer than 30 characters"}}            
            width={1}
            name="name" 
            value={this.state.data.name}
            control={(<Input type="text" placeholder="Name" fluid/>)}
            hint={(<React.Fragment>Please enter your username, e.g. <code>john.smith</code></React.Fragment>)}/>          
          <Form.Field 
            required={{message: "Email is required"}}
            name="email" 
            value={this.state.data.email}
            control={(<Input disabled={p.disabled} type="text" placeholder="Email" fluid/>)}
            hint={(<React.Fragment>Please enter your email address.</React.Fragment>)}
            />
          <Form.Field 
            required={{message: "Password is required"}}
            minLength={{length: 8, message: "Your password must have at least 8 characters."}}
            maxLength={{length: 30, message: "Your password must have no more than 30 characters."}}
            noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: "Your password must contain letters, digits and at least one special character."}}
            name="password" 
            value={this.state.data.password}
            control={(<Password disabled={p.disabled} placeholder="Password" fluid/>)}
            hint={(<React.Fragment>Please enter your password.</React.Fragment>)}
            />            
        </Form>
      </div>
    )
  }
}

export { SignupForm, ISignup };