import * as React from 'react';

import { Country, CountryFactory, Project, ProjectFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, Query, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export } from '../../modules';

import { Form } from '@independent-software/typeui/controls/Form';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Flag } from '../../controls/Flag/Flag';
import { NA } from '../../formatters';

interface IProps extends IListProps {
  /**
   * If set, list is prefiltered by a country, and
   * country column and filter are hidden.
   */
  countryId?: number;
  /**
   * If set, list is prefiltered by a user.
   */
  userId?: number;
  /**
   * Event is fired when a project is clicked.
   */
  onClick?: (project: Project) => void;
}

interface IState extends IListState<Project> {
  // Data for countries dropdown
  countries: Country[];
}

class ListProjects extends List<Project, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, ProjectFactory, 'name', 'asc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      countries: [],
    };

    // The country stored in the Query is an ID, not a Country.
    // Is the list prefiltered by a country? Then set a filter.
    if(this.props.countryId) {
      this.setFilter('country', 'eq', { id: this.props.countryId }, true);
    }

    // The user stored in the Query is an ID, not a User.
    // Is the list prefiltered by a user? Then set a filter.
    if(this.props.userId) {
      this.setFilter('user', 'eq', { id: this.props.userId }, true);
    }
  }

  componentDidMount() {
    if(!this.props.countryId) this.handleCountrySearch();
  }

  handleSearch = (value: string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeCountry = (value: Country) => {
    this.setFilter('country', 'eq', value);
  }

  handleCountrySearch = (q?:string) => {
    // Retrieve a list of countries:
    let query = new Query('name', 'asc');
    if (q) query.setFilter('q', 'like', q);
    CountryFactory.getSome(this.props.auth, 0, 8, query)
      .then((res) => this.setState({ countries: res.items }));
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search records">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        {!p.countryId && 
          <Form.Uncontrolled hint="Filter by country">
            <Dropdown onSearch={this.handleCountrySearch} name="country" fluid clearable value={this.getFilter('country', 'eq')} data={this.state.countries} placeholder="Country" label={(item:Country) => item.name} onChange={this.handleChangeCountry}>
              <Dropdown.Column weight={1}>{(item:Country) => <span><Flag code={item.code} name={item.name} size='mini'/> {item.name}</span>}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={p.onClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={2} label="Name" order="name" dir="asc">{(item:Project) => item.name}</DataTable.Column>
          <DataTable.Column weight={2} label="Partner" order="partner" dir="asc">{(item:Project) => item.partner}</DataTable.Column>
          {!p.countryId && <DataTable.Column weight={1} label="Country">{(item:Project) => item.country ? <Flag code={item.country.code} name={item.country.name} size='mini'/> : <NA/>}</DataTable.Column>}
          <DataTable.Column weight={1} label="Devices" order="devices_count" dir="desc" align="right">{(item:Project) => item.devices_count == 0 ? <NA/> : item.devices_count}</DataTable.Column>
          <DataTable.Column weight={1} label="" align="right">{(item:Project) => item.public ? <Icon name='unlock' color='darkgreen' title="public"/> : <Icon name='lock' color='darkred' title="private"/>}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListProjects };

