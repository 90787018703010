import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';
import { Topbar, Sidebar } from './modules/';
import { Window } from './Window';
import { Route, Switch, RouteComponentProps } from 'react-router';
import { Auth } from './services/Auth';
import { routes, menu } from './config/Routes';

interface IScreenProps {
  className?: string;
  children?: React.ReactNode;
  auth: Auth;
  onSignout: () => void;
  onSignin: (auth: Auth) => void;
  onSignup: (auth: Auth) => void;
}

interface IScreenState {
  menuOpen: boolean
}

class ScreenBase extends React.Component<IScreenProps & RouteComponentProps, IScreenState> {

  constructor(props: IScreenProps & RouteComponentProps) {
    super(props);
    this.handleToggleSidebar = this.handleToggleSidebar.bind(this);
  }

  readonly state = {
    menuOpen: false
  }

  handleToggleSidebar() {
    // Toggle menu open state.
    this.setState((prevState) => {
      return {
        menuOpen: !prevState.menuOpen
      }
    });
  }

  // 
  // Apply a Switch to the route collection to find out the current route.
  // Render a TopBar with the current route's title.
  // 
  getTopBar() {
    let p = this.props;
    return (
      <Switch>
        {Object.keys(routes).map((key, index) => {
          let route = routes[key];
          return <Route key={index} exact path={route.path} render={() => (
            <Topbar 
              history={p.history}
              onMenu={this.handleToggleSidebar} 
              auth={p.auth}
              onSignin={p.onSignin}
              onSignup={p.onSignup}
              onSignout={p.onSignout}>
              {route.title}
            </Topbar>
          )}/>
        })}              
      </Switch>
    );
  }

  render() {
    let p = this.props;

    return (
      <div className={p.className}>
        <Sidebar history={p.history} location={p.location} match={p.match} auth={p.auth} open={this.state.menuOpen} onClose={this.handleToggleSidebar} menu={menu}/>
        <Window open={this.state.menuOpen}>
          {this.getTopBar()}
          <Content>
            {p.children}
          </Content>
        </Window>
      </div>
    );
  }
}

const Content = styled('div')`
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
  flex: 1;
`;

const Screen = styled(ScreenBase)`
  /* Screen covers the entire viewport. */
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  /* Offscreen items are hidden,
     like the Window when it is shifted. */
  overflow: hidden; 
`

export { Screen };
