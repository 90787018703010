import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Label } from '@independent-software/typeui/controls/Label';

interface IGpsProps {
  className?: string;
  /** Value to format. */
  latitude: number | string;
  longitude: number | string;
}

class GpsBase extends React.Component<IGpsProps, {}> {
  
  // Convert a decimal value to degrees, minutes, and seconds (as a string).
  private toDMS = (value: number): string => {
    value = Math.abs(value);
    let degrees = Math.floor(value);
    let md = Math.abs(value - degrees) * 60;
    let minutes = Math.floor(md);
    let seconds = ((md - minutes) * 60).toFixed(1);
    return `${degrees}\u00B0${minutes}'${seconds}''`;
  }

  // Convert decimal latitude to degrees, minuts, seconds (as a string), 
  // and append N or S.
  private latitudeToDMS = (value:number): string => {
    return `${this.toDMS(value)}${value >= 0 ? 'N': 'S'}`;
  }

  // Convert decimal longitude to degrees, minuts, seconds (as a string), 
  // and append E or W. 
  private longitudeToDMS = (value:number): string => {
    return `${this.toDMS(value)}${value >= 0 ? 'E': 'W'}`;
  }

  render() {
    let p = this.props;

    // Do nothing if either lat/lng are null:
    if(p.latitude == null || p.longitude == null) return <React.Fragment>&mdash;</React.Fragment>;

    // Convert lat/lng to numbers if they are strings:
    let latitude:number = typeof p.latitude == 'number' ? p.latitude : parseFloat(p.latitude);
    let longitude:number = typeof p.longitude == 'number' ? p.longitude : parseFloat(p.longitude);

    return (
      <div className={p.className}>
        <Label.Group basic size="small" color="MidnightBlue">
          <Label>lat <Label.Detail>{this.latitudeToDMS(latitude)}</Label.Detail></Label>
          <Label>lon <Label.Detail>{this.longitudeToDMS(longitude)}</Label.Detail></Label>
          <Label>gps <Label.Detail>{latitude.toFixed(6)}, {longitude.toFixed(6)}</Label.Detail></Label>
        </Label.Group>
      </div>
    )
  }
}

const Gps = styled(GpsBase)`
  margin: 4px 0 4px 0;
`

export { Gps };
