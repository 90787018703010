import * as React from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { routes } from './config/Routes';
import { Screen } from './Screen'; 
import { Auth } from './services/Auth';
import { ToastContainer } from '@independent-software/typeui/controls/Toast';
import { AccessDenied } from './components/AccessDenied/AccessDenied';
import { LoginRequired } from './components/LoginRequired/LoginRequired';

interface IAppContainerState {
  // Auth data (or null)
  auth: Auth;
}

type AccessResult = 'login' | 'denied' | 'ok';

class AppContainer extends React.Component<{}, IAppContainerState> {
  constructor(props: {}) {
    super(props);
    let auth = Auth.restore();
    this.state = {
      auth: auth,
    };
  }

  handleSignin = (auth: Auth) => {
    this.setState({
      auth: auth,
    });
  }

  handleSignup = (auth: Auth) => {
    this.setState({
      auth: auth,
    });
  }

  handleSignout = () => {
    this.state.auth.logout();
    this.setState({
      auth: null /* this.state.auth */
    });
  }

  render() {
    let p = this.props;

    let routeBlock = Object.keys(routes).map((key, index) => {
      let route = routes[key];

      return <Route 
        key={index} 
        exact 
        path={route.path} 
        render={(props) => {
          
          // Determine if the user has access:
          let result: AccessResult = 'ok'; // Access is granted, unless...
          if(route.right !== false) { // Route requires a right
            if(route.right === true && this.state.auth == null) {
              result = 'login'
            } else if(route.right !== true && this.state.auth == null) {
              result = 'login'
            } else if(route.right !== true && !this.state.auth.hasRight(route.right)) { // No right?
              result = 'denied'
            }
          }

          // Instantiate component:
          let component = null;
          switch(result) {
            case 'login':
              component = <LoginRequired {...props} onSignin={this.handleSignin} onSignup={this.handleSignup}/>;
              break;
            case 'denied':
              component = <AccessDenied auth={this.state.auth}/>;
              break;
            default:
              component = React.createElement(route.component, {...props, auth: this.state.auth});              
              break;
          }

          return <Screen {...props} 
            auth={this.state.auth} onSignin={this.handleSignin} 
            onSignup={this.handleSignup} onSignout={this.handleSignout}>
            {component}
          </Screen>; }}
        />
    });

    return (
      <React.Fragment>
        <ToastContainer maxToasts={5}/>
        <Router>
          <Switch>
            {routeBlock}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export { AppContainer };