import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme';
import { css } from 'styled-components'; 
import { RouteComponentProps } from 'react-router';
import { ResetForm } from './ResetForm';
import { IReset } from './IReset';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { App } from '../../App';
import { Container, Content, BottomBar, Section } from '../../modules';
import { Message } from '@independent-software/typeui/controls/Message';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Flex } from '@independent-software/typeui/controls/Flex';

interface ILoginProps {
  className?: string;
}

interface IState {
  reset: IReset;
  isValid?: boolean
  /** Reset request in progress? Will show a loader. */
  resetLoading: boolean;  
}
//$2y$10$1pGwZhl2UpF3FMokOZv0NOaiMrk3p4EL6DBfwbcNMB44I.Mo/Z1w2
//$2y$10$5T9kR7jFSsUF/K7Zftg58e5/z0VQ9XkAM8qDHqaL4UuQJmKp2NKmS

class ResetPassword extends React.Component<ILoginProps & RouteComponentProps, IState> {
  private messageTimer: number = null;
  private token: string;

  constructor(props: ILoginProps & RouteComponentProps) {
    super(props);
    this.token = new URLSearchParams(props.location.search).get('token');
    this.state = {
      reset: {
        password: ''
      },
      resetLoading: false
    }
  }

  handleChange = (reset: IReset, forceupdate: boolean) => {
    this.setState({
      reset: reset
    });
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }  

  // If there is a message timeout still running,
  // clear it before unmounting.
  componentWillUnmount() {
    if(this.messageTimer) {
      clearTimeout(this.messageTimer);
    }
  }


  // Adds a 500ms timeout to make sure that login button
  // animation actually gets seen.
  handleSubmit = () => {
    this.setState({
      resetLoading: true
    });
    setTimeout(() => {
      axios.post(`${App.apiURL}/reset`, {
        token: this.token,
        password: this.state.reset.password
      })
      .then(response => {
        this.props.history.replace('/');
      })
      .catch(error => {
        let text = '';
        if(error.response) {
          switch(error.response.status) {
            case 400: 
              text = "You did not provide a valid new password.";
              break;
            case 401:
              text = "Your password reset token was not valid. Please request a new token.";
              break;
            default:
              text = "There was a problem communicating with the server.";
              break;
          }          
        } else if(error.request) {
          text = "No server response was received.";
        } else {
          text = "There was a problem sending your request.";
        }
        ToastService.toast(text);
      })
      .then(() => {
        this.setState({
          resetLoading: false,
        });
      });
    }, 500);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <Section padded>
            <Flex>
              <Flex.Row>
                <Flex.Column>
                  <Door><use xlinkHref={"sprites.svg#door"}></use></Door>
                </Flex.Column>
                <Flex.Column width={1}>
                  <Divider hidden/>
                  <Message icon type="info">
                    <Icon name="lock" size="big"/>
                    <Message.Content>
                      <Message.Header>
                        Password reset request
                      </Message.Header>
                      <p>
                        You have submitted a request to reset a forgotten password. Please enter your new password below.
                      </p>
                    </Message.Content>
                  </Message>
                  <Divider/>
                  <ResetForm disabled={this.state.resetLoading} data={this.state.reset} onChange={this.handleChange} onValidate={this.handleValidate}/>
                </Flex.Column>
              </Flex.Row>
            </Flex>
          </Section>
        </Content>
        <BottomBar>
          <Button primary disabled={!this.state.isValid} onClick={this.handleSubmit}>
            {this.state.resetLoading && <Icon loading name="circle-notch"/>}
            {!this.state.resetLoading && <span>Save password</span>}
            {this.state.resetLoading && <span>&nbsp;Saving password...</span>}
          </Button>        
        </BottomBar>
      </Container>
    )
  }
}

const Door = styled('svg')`
  width: 150px;
  height: 255px;
  margin-right: 30px;
`

export { ResetPassword };