import * as React from 'react';
import { Section, Viewer } from '../../modules';
import { Country, CountryFactory, Project } from '../../resource/'; 
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { Image } from '@independent-software/typeui/controls/Image';

import { ListProjects } from '../Project';
import { Label } from '@independent-software/typeui/controls/Label';
import { ToastService } from '@independent-software/typeui/controls/Toast';

class ViewCountry extends React.Component<IAuthProps & RouteComponentProps<{id: string}>, {}> {
  private handleProjectClick = (item:Project) => {
    this.props.history.push(`/projects/${item.id}`);
  }

  private canEdit = (country: Country): boolean => {
    return this.props.auth && this.props.auth.hasRight('can_edit_countries');
  }

  private handleEdit = () => {
    this.props.history.push(`/countries/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Country deleted");
    this.props.history.goBack();    
  }

  render() {
    let p = this.props;
    return (
      <Viewer<Country> 
        auth={p.auth}
        id={parseInt(p.match.params.id)}
        factory={CountryFactory}
        canEdit={this.canEdit}
        onEdit={this.handleEdit}
        onDelete={this.handleDelete}
        content={(item: Country) => 
          <React.Fragment>
            <Section dark padded>
              <Header size="h3">
                <Image src={`https://www.countryflags.io/${item.code}/shiny/64.png`} alt="Flag" size="mini"/>
                {item.name}
              </Header>
            </Section>
            <Tabs underlined>
              <Tabs.Pane label={<span>Projects <Label>{item.projects_count}</Label></span>}>
                <ListProjects auth={p.auth} name="country_projects" countryId={item.id} onClick={this.handleProjectClick}/>
              </Tabs.Pane>                
            </Tabs>   
          </React.Fragment>}>
      </Viewer>
    );
  }
}

export { ViewCountry };
