import * as React from 'react';
import 'leaflet';
import 'leaflet.markercluster';
const L = (window as any).L;
import styled from '@independent-software/typeui/styles/Theme'
import { darken } from '@independent-software/typeui/helper/darken';
import { css } from 'styled-components';
import { Project } from '../../resource';
import { MAP } from '../../config/Config';

interface IMapProps {
  className?: string;
  children?: React.ReactNode;
  projects: Project[];
  zoom?: number;
}

class ProjectsMapBase extends React.Component<IMapProps, {}> {
  private mapElement: HTMLDivElement;
  private map: L.Map;
  private cluster: L.MarkerClusterGroup;

  componentDidMount() {
    this.map = L.map(this.mapElement, {
      center: [0,0],
      zoom: this.props.zoom ? this.props.zoom : 3,
      zoomControl: false,
      layers: [
        L.tileLayer(MAP.url, {
          attribution: MAP.attribution
        }),
      ]
    });

    // Create cluster group for markers:
    this.cluster = L.markerClusterGroup({
      iconCreateFunction: function(cluster: L.MarkerCluster) {
        let n = cluster.getAllChildMarkers().length;
        return L.divIcon({ html: n, className: 'cluster', iconSize: L.point(32,32)})
      }
    });
    this.map.addLayer(this.cluster);    

    // Add zoom control at bottom right:
    L.control.zoom({ position: 'bottomright' }).addTo(this.map);

    this.addProjects();
  }

  componentDidUpdate() {
    this.removeProjects();
    this.addProjects();
  }

  private removeProjects = () => {
    this.cluster.clearLayers();
  }

  private addProjects = () => {
    this.props.projects.forEach((project) => {
      this.addProject(project);
    });
    this.zoomToMarkers();
  }

  private addProject = (project: Project) => {
    let fillColor = '#76FD86';
    let strokeColor = darken(0.5, fillColor);
    let circleOptions = {
      radius: 6, 
      fillColor: fillColor, 
      color: strokeColor, 
      weight: 1,
      fill: true,
      fillOpacity: 1
    };
    let marker: L.CircleMarker = L.circleMarker([parseFloat(project.latitude), parseFloat(project.longitude)], circleOptions);
    marker.bindPopup(
      `<table class="popup">
        <tbody>
          <tr><td>Project</td><td style="font-weight:bold"><a href="#/projects/${project.id}">${project.name}</a></td></tr>
          <tr><td>Country</td><td>${project.country.name}</td></tr>
          <tr><td>Beneficiaries</td><td>${parseInt(project.beneficiaries).toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 })}</td></tr>
        </tbody>
      </table>
      `);

    this.cluster.addLayer(marker);
  }

  private zoomToMarkers = () => {
    let bounds = this.cluster.getBounds();
    if(!bounds.isValid()) return;
    this.map.fitBounds(this.cluster.getBounds(), { animate: false });
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.mapElement = el}>{p.children}</div>
    );
  }
}

const ProjectsMapStyled = styled(ProjectsMapBase)`
  width: 100%;
  height: 100%;
`;

class ProjectsMap extends React.Component<IMapProps, {}> {
  public static displayName = "ProjectsMap";

  render() {
    let p = this.props;
    return (
      <ProjectsMapStyled {...p}></ProjectsMapStyled>
    )
  }
}

export { ProjectsMap };