import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Reading, ReadingFactory } from '../../resource/'; 
import { IAuthProps, List, IListState, IListProps, SearchFilter } from '../../services/';
import { IconBar, Export } from '../../modules';

import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { parse, format } from 'date-fns';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Label } from '@independent-software/typeui/controls/Label';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Form } from '@independent-software/typeui/controls/Form';
import { Progress } from '@independent-software/typeui/controls/Progress';
import { Input } from '@independent-software/typeui/controls/Input';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IProps extends IListProps {
  /**
   * If set, list is prefiltered by a device and
   * device column and filter are hidden.
   */
  deviceId?: number;  
  /**
   * Event is fired when an item is clicked.
   */
  onClick?: (reading: Reading) => void;
}

interface IState extends IListState<Reading> {
  deleting: Reading;
  dontConfirm: boolean;
}

class ListReadings extends List<Reading, IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props, ReadingFactory, 'datetime', 'desc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      deleting: null,
      dontConfirm: false
    };

    // The device stored in the Query is an ID, not a Device.
    // Is the list prefiltered by a device? Then set a filter.
    if(this.props.deviceId) {
      this.setFilter('device', 'eq', { id: this.props.deviceId }, true);
    }        
  }

  // Deleted readings can't be clicked
  handleClick = (reading: Reading) => {
    if((reading as any).deleted) return;
    this.props.onClick(reading);
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }  

  handleChangeFromDate = (value:string) => {
    if(value != null) value = value + " 00:00:00";
    this.setFilter('date', 'gte', value);
  }  

  handleChangeToDate = (value:string) => {
    if(value != null) value = value + " 23:59:59";
    this.setFilter('date', 'lte', value);
  }   

  deleteReading = (reading: Reading) => {
    (reading as any).deleted = true;
    this.setState({ deleting: null });
    reading.$delete(this.props.auth)
      .then(res => {
        // On success, display a toast.
        ToastService.toast("Reading deleted.");
      })
      .catch(error => {
        // Ignore errors
      })
  }
  
  handleDeleteClick = (e: React.MouseEvent, reading: Reading) => {
    e.stopPropagation(); // Prevent list item itself from being clicked.
    if(this.state.dontConfirm) {
      this.deleteReading(reading);
    } else {
      this.setState({ deleting: reading });
    }
  }

  handleDelete = () => {
    this.deleteReading(this.state.deleting);
  }

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search values">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="Filter by start date">
          <Input name="from" type="date" fluid clearable value={this.getFilter('date', 'gte')} placeholder="From date" onChange={this.handleChangeFromDate}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="Filter by end date">
          <Input name="to" type="date" fluid clearable value={this.getFilter('date', 'lte')} placeholder="To date" onChange={this.handleChangeToDate}/>
        </Form.Uncontrolled>
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    // Note on deleted readings
    // --
    // Deleted readings are not removed from the list, since that would cause the table to reload
    // or contract, which would be confusing. Instead, they are greyed out upon deletion.

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar> 
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onClick={this.handleClick} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          <DataTable.Column weight={3} label="Time" order="datetime" dir="desc">
            {(item:Reading) => {
              let value = format(parse(item.datetime.toString(), 'yyyy-MM-dd HH:mm:ss', new Date()), 'dd-MM-yyyy HH:mm:ss')
              return ((item as any).deleted) ? <Deleted>{value}</Deleted> : value;
            }}
          </DataTable.Column>
          <DataTable.Column weight={1} label="Value (cm)" order="value" dir="asc" align="right">
            {(item:Reading) => {
              let value = <Number value={item.value} decimals={2}/>
              return ((item as any).deleted) ? <Deleted>{value}</Deleted> : value;
            }}
          </DataTable.Column>          
          <DataTable.Column weight={1} force label={<React.Fragment>Result (m<sup>3</sup>)</React.Fragment>} order="result" dir="asc" align="right">
            {(item:Reading) => {
              let value = <Number value={item.result} decimals={2}/>
              return ((item as any).deleted) ? <Deleted>{value}</Deleted> : value;
            }}
          </DataTable.Column>
          <DataTable.Column weight={1} label="Percentage" order="percentage" dir="asc">
            {(item:Reading) => {
              let value = <React.Fragment><Progress value={item.percentage} background numbered/></React.Fragment>
              return ((item as any).deleted) ? <Deleted>{value}</Deleted> : value;
            }}
          </DataTable.Column>
          <DataTable.Column weight={1} label="Quality" order="quality" dir="asc" align="right">
            {(item:Reading) => {
              let value = item.quality ? <React.Fragment><Number value={item.quality} decimals={0}/><Small>/10</Small></React.Fragment> : '-'
              return ((item as any).deleted) ? <Deleted>{value}</Deleted> : value;
            }}
          </DataTable.Column>
          <DataTable.Column weight={1} label="Temp (&ordm;C)" order="temperature" dir="asc" align="right">
            {(item:Reading) => {
              let value = item.temperature ? <Number value={item.temperature} decimals={0}/> : '-'
              return ((item as any).deleted) ? <Deleted>{value}</Deleted> : value;
            }}
          </DataTable.Column>
          <DataTable.Column force weight={1} label="" align="right">
            {(item:Reading) => {
              // Determine if item can deleted.
              // Can delete if logged in, and item was created by user
              // Can delete if logged in, and user can edit devices
              let canDelete = this.props.auth && (item.created_by == this.props.auth.id || this.props.auth.hasRight('can_edit_devices'));
              if(!canDelete) return '';
              // If item was already deleted, show "deleted"
              // Otherwise, show delete button.
              let isDeleted = (item as any).deleted;
              return isDeleted
                ? <Label size="small">Deleted</Label>
                : <Button negative size="tiny" icon onClick={(e:React.MouseEvent) => this.handleDeleteClick(e, item)}><Icon name="trash"/></Button>
            }}
          </DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
        <Dialog.Confirm open={this.state.deleting != null} onClose={() => this.setState({deleting: null})} onConfirm={this.handleDelete}>
          Are you sure you wish to delete this reading permanently from the database?
          <Divider hidden/>
          <Checkbox checked={this.state.dontConfirm} onChange={(value) => this.setState({dontConfirm: value})} label="Don't ask me again"/>
        </Dialog.Confirm>
      </React.Fragment>
    );
  }
}

const Deleted = styled('span')`
  color: #aaa;
`;

const Small = styled('span')`
  font-size: 70%;
`;

export { ListReadings };
