import * as React from 'react';
import { Container, Content, BottomBar, Section } from '../../modules';
import { ProfileForm } from './ProfileForm';
import { Profile, ProfileFactory } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';

type TStep = 'ready' | 'saving' | 'saveError';

interface IAddProfileState {
  profile: Profile;
  isValid: boolean;
  step: TStep;
  error: any;
  dirty?: boolean;
}

class AddProfile extends React.Component<IAuthProps & RouteComponentProps<any>, IAddProfileState> {
  private profile: Profile;

  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.profile = ProfileFactory.create();
    this.state = {
      step: 'ready',
      isValid: false,
      error: null,
      profile: this.profile
    };
  }

  handleChange = (profile: Profile, forceupdate: boolean) => {
    this.profile = Object.assign(this.profile, profile);
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }    

  handleSubmit = () => {
    this.setState({ profile: this.profile, dirty: true });
    if(!this.state.isValid) return;    

    this.setState({error: null, step: 'saving'});
    this.profile.$save(this.props.auth)
      .then(res => {
        ToastService.toast("Profile created");
        this.props.history.push("/profiles");
      })
      .catch(error => {
        this.setState({
          step: 'saveError',
          error: error
        })
      });
  }

  private handleCancelSave = () => {
    this.setState({ step: 'ready' });
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {this.state.step == 'saving' && <Loader></Loader>}
        <React.Fragment>
          <Content>
            <Section padded>
              <ProfileForm dirty={this.state.dirty} data={this.state.profile} onChange={this.handleChange} onValidate={this.handleValidate}/>
            </Section>
          </Content>
          <BottomBar>
            <Button primary onClick={this.handleSubmit}><Icon name="save"/> Save</Button>
          </BottomBar>
        </React.Fragment>
        <Dialog.Xhr open={this.state.step == 'saveError'} error={this.state.error} onClose={this.handleCancelSave} onRetry={this.handleSubmit}/>
      </Container>
    );
  }
}

export { AddProfile };
