import * as React from 'react';

import { Container, Content, Section } from '../../modules';
import { Message } from '@independent-software/typeui/controls/Message';
import { Icon } from '@independent-software/typeui/controls/Icon';
import styled from '@independent-software/typeui/styles/Theme';
import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Auth } from '../../services';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { RouteComponentProps } from 'react-router';
import { SignInUp } from '../SignInUp/SignInUp';

interface ILoginRequiredProps {
  onSignin: (auth: Auth) => void;
  onSignup: (auth: Auth) => void;
}

interface ILoginRequiredState {
  signinOpen: boolean;
}

class LoginRequired extends React.Component<ILoginRequiredProps & RouteComponentProps, ILoginRequiredState> {
  constructor(props: ILoginRequiredProps & RouteComponentProps) {
    super(props);
    this.state = {
      signinOpen: false
    };
  }

  handleClickSignin = () => {
    this.setState({
      signinOpen: true
    });
  }

  handleCancelSignin = () => {
    this.setState({
      signinOpen: false
    });
  }
  
  handleSignin = (auth: Auth) => {
    this.setState({
      signinOpen: false
    })
    this.props.onSignin(auth);
  }

  handleSignup = (auth: Auth) => {
    this.setState({
      signinOpen: false
    })
    this.props.onSignup(auth);
  }

  handleDashboard = () => {
    this.props.history.push('/');
  }

  render() {
    return (
      <Container>
        <Content>
          <Section padded>
            <Flex>
              <Flex.Row>
                <Flex.Column>
                  <Door><use xlinkHref={"sprites.svg#door"}></use></Door>
                </Flex.Column>
                <Flex.Column width={1}>
                  <Divider hidden/>
                  <Message icon type="error">
                    <Icon name="lock" size="big"/>
                    <Message.Content>
                      <Message.Header>
                        Sign-in required
                      </Message.Header>
                      <p>
                        This area of the application is secured and requires that you sign in.
                      </p>
                    </Message.Content>
                  </Message>
                  <Divider hidden/>
                  <Button primary onClick={this.handleClickSignin}>Sign in</Button>
                  <Button onClick={this.handleDashboard}>Go to dashboard</Button>
                </Flex.Column>
              </Flex.Row>
            </Flex>
          </Section>
        </Content>
        <Dialog open={this.state.signinOpen} onClose={this.handleCancelSignin}>
          <SignInUp onSignin={this.handleSignin} onSignup={this.handleSignup}/>
        </Dialog>        
      </Container>
    );
  }
}

const Door = styled('svg')`
  width: 150px;
  height: 255px;
  margin-right: 30px;
`

export { LoginRequired };
