import { Model, ResourceFactory } from '../services/';

class Log extends Model {
  public username: string = '';
  public useremail: string = '';
  public message: string = '';
  public created_at: string = '';
}

let LogFactory = ResourceFactory.create<Log>(Log, 'log');

export { Log, LogFactory };