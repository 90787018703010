import * as React from 'react';
import { Profile } from '../../resource/';
import { accessGroups } from '../../config/Config';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IProps {
  /** Initial form data. */
  data: Profile;
  /** Called whenever form changes. */
  onChange: (data: Profile, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */
  dirty?: boolean;  
}

interface IState {
  /** Current form data. */
  data: Profile;  
}

class ProfileForm extends React.Component<IProps, IState> {
  constructor(props:any) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <div>
        <Form 
          data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>

          <Form.Group equal>
            <Form.Field 
              required={{message: "Name is required."}}
              minLength={{length: 3, message: "Name must be at least 3 characters."}}
              maxLength={{length: 30, message: "Name may not be longer than 30 characters."}}
              label="Profile name"
              name="name" 
              value={this.state.data.name}
              control={(<Input type="text" placeholder="Name" fluid/>)}
              hint={(<React.Fragment>Please enter a profile name, e.g. <code>Data Entry</code></React.Fragment>)}/>

            <Form.Field
              name="is_default"
              value={this.state.data.is_default}
              control={<Checkbox label="This is the default profile for new users" checked={this.state.data.is_default}/>}
            />
          </Form.Group>
          
          <Divider hidden/>

          <Flex.Columns count={2}>
          {accessGroups.map((group, index) => 
            <React.Fragment key={index}>
              <Segment secondary raised attached="top">
                <b>{group.title}</b>
              </Segment>
              <Segment raised attached="bottom">
                {group.rights.map((right, index) => 
                  <Form.Field 
                    key={index}
                    name={right.name} 
                    value={(this.state.data as any)[right.name] == 1}
                    control={(<Checkbox label={right.title} checked={(this.state.data as any)[right.name] == 1}/>)}
                    />
                )}
              </Segment>        
            </React.Fragment>
          )}
          </Flex.Columns>

        </Form>      
      </div>
    )
  }
}

export { ProfileForm };
