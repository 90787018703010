import * as React from 'react';
import axios from 'axios';
import { App } from '../../App';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Section } from '../../modules';
import { Form } from '@independent-software/typeui/controls/Form';
import { ResponsiveContainer, LineChart, XAxis, YAxis, ReferenceLine, Line, ReferenceArea, Tooltip, CartesianGrid, Rectangle } from 'recharts';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { format, getUnixTime, subYears } from 'date-fns';
import { Button } from '@independent-software/typeui/controls/Button';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Number } from '@independent-software/typeui/formatters/Number';
import styled from '@independent-software/typeui/styles/Theme';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ButtonGroup } from '@independent-software/typeui/controls/Button/ButtonGroup';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Header } from '@independent-software/typeui/controls/Header';
import { ValueStore } from '../../services';
import { IValueType, ValueTypes } from './types/ValueType';
import { ILineType, LineTypes } from './types/LineType';
import { Input } from '@independent-software/typeui/controls/Input';
import { saveAs } from 'file-saver';
import { Label } from '@independent-software/typeui/controls/Label';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IPoint {
  d: number;
  v: number;
  r: number;
  q: number;
  t: number;
  p: number;
}

interface IProps {
  device_id: number;
  maxValue: number;
  maxResult: number;
}

interface IState {
  valuetype: IValueType;
  linetype: ILineType;
  showDots: boolean;
  numPoints: number;
  showTooltip: boolean;
  showPanel: boolean;
  period: number;
  points: IPoint[];
  startdate: number;
  enddate: number;
  refAreaLeft: number;
  refAreaRight: number;  
}

function toUTC(unix: number) {
  let date = new Date(unix * 1000);
  date = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  return date;
}

class DeviceChart extends React.Component<IProps, IState> {
  private wrapperRef: HTMLElement;

  constructor(props: IProps) {
    super(props);

    let startdate = getUnixTime(subYears(new Date(), 1));
    let enddate = getUnixTime(new Date());

    this.state = {
      valuetype: this.getValueType(),
      linetype: this.getLineType(),
      showDots: this.getShowDots(),
      numPoints: this.getNumPoints(),
      showTooltip: true,
      showPanel: false,
      period: 30,
      points: [],
      startdate: startdate,
      enddate: enddate,
      refAreaLeft: null,
      refAreaRight: null
    };
  }

  componentDidMount() {
    // Retrieve all available data (full chart, no zooming):
    this.getData(this.state.startdate, this.state.enddate);
  }

  // Retrieve chart data from server, between startdate and enddate. 
  // The data is stored as points for the chart. 
  private getData = (startdate: number, enddate: number) => {
    // Convert dates to UTC by looking up browser time zone offset (in minutes),
    // then adding that (in seconds) to the dates.
    let tzOffset = new Date().getTimezoneOffset() * 60; // tz offset in seconds

    // Look up more chart data on either side of the date range
    // in order to have dots to connect to off-screen.
    let diff = (enddate - startdate) >> 2;

    axios.get(`${App.apiURL}readings/chart/${this.props.device_id}`, { params: 
      { 
        startdate: startdate - diff + tzOffset,
        enddate: enddate + diff + tzOffset,
        points: this.state.numPoints
      }
    })
    .then(response => {
      let points = response.data;
      this.setState({ 
        startdate: startdate,
        enddate: enddate,
        points: points 
      });
    });
  }

  private getLineType = (): ILineType => {
    let id = ValueStore.get('linetype', 'linear');
    return LineTypes.find((lt) => lt.id == id);
  }

  private getValueType = (): IValueType => {
    let id = ValueStore.get('valuetype', 'r');
    return ValueTypes.find((vt) => vt.id == id);
  }

  private getShowDots = (): boolean => {
    return ValueStore.get('showdots', false);
  }

  private getNumPoints = (): number => {
    return ValueStore.get('numPoints', 500);
  }

  private handleChangeValuetype = (value: IValueType) => {
    ValueStore.set('valuetype', value.id);
    this.setState({ valuetype: value });
  }

  private handleChangeLinetype = (value: ILineType) => {
    ValueStore.set('linetype', value.id);
    this.setState({ linetype: value });
  }

  private handleChangeShowDots = (value: boolean) => {
    ValueStore.set('showdots', value);
    this.setState({ showDots: value });
  }

  private handleChangeNumPoints = (value: number) => {
    ValueStore.set('numPoints', value);
    this.setState({ numPoints: value }, () => {
      this.getData(this.state.startdate, this.state.enddate);
    });
  }

  private handleChangeStartDate = (value: string) => {
    let startdate = getUnixTime(new Date(value));
    if(startdate > this.state.enddate) startdate = this.state.enddate;
    this.setState({ period: 0 });
    this.getData(startdate, this.state.enddate);
  }

  private handleChangeEndDate = (value: string) => {
    let enddate = getUnixTime(new Date(value + ' 23:59:59'));
    if(enddate < this.state.startdate) enddate = this.state.startdate;
    this.setState({ period: 0 });
    this.getData(this.state.startdate, enddate);
  }  

  private setPeriod = (days: number) => {
    this.setState({ period: days });
    let enddate = getUnixTime(new Date());
    let startdate = enddate - days * 60 * 60 * 24; // days
    this.getData(startdate, enddate);
  }

  private handleMouseDown = (e: React.MouseEvent) => {
    // Cancel if clicked outside of chart area.
    if(e == null) return;

    // let unixTime = this.mouseToUnixTime(e);
    this.setState({
      showTooltip: false,
      refAreaLeft: (e as any).activeLabel
    })
  }

  private handleMouseMove = (e: React.MouseEvent) => {
    if(!this.state.refAreaLeft || e == null) return;
    this.setState({
      refAreaRight: (e as any).activeLabel
    })
  }

  private handleMouseUp = (e: React.MouseEvent) => {
    this.setState({ showTooltip: true });
    if(!this.state.refAreaLeft || !this.state.refAreaRight) {
      this.setState({
        refAreaLeft: null,
        refAreaRight: null
      });
      return;
    }

    // Ref area may be left-right or right-left:
    let start = Math.min(this.state.refAreaLeft, this.state.refAreaRight);
    let end = Math.max(this.state.refAreaLeft, this.state.refAreaRight);

    this.getData(start, end);
    this.setState({ period: 0 });

    this.setState({
      refAreaLeft: null,
      refAreaRight: null
    });
  }

  private handleMouseLeave = (e: React.MouseEvent) => {
    this.setState({
      refAreaLeft: null,
      refAreaRight: null
    })
  }

  private handleZoomIn = () => {
    // Make range twice as small
    let diff = (this.state.enddate - this.state.startdate) >> 2;
    let startdate = this.state.startdate + diff;
    let enddate = this.state.enddate - diff;
    this.setState({ period: 0 });
    this.getData(startdate, enddate);
  }

  private handleZoomOut = () => {
    // Make range twice as big
    let diff = (this.state.enddate - this.state.startdate) >> 1;
    if(diff == 0) diff = 1;
    let startdate = this.state.startdate - diff;
    let enddate = this.state.enddate + diff;
    let today = getUnixTime(new Date());
    if(enddate > today) enddate = today;
    this.setState({ period: 0 });
    this.getData(startdate, enddate);
  }

  //
  // Pan chart left or right by one-tenth
  //
  private handlePan = (negative: boolean) => {
    // Get one-tenth of total date range.
    let diff = Math.floor((this.state.enddate - this.state.startdate) * 0.1);
    // If negative, we're panning left:
    if(negative) diff = -diff;
    // Add this value to start and end dates.
    this.setState({ period: 0 });
    this.getData(this.state.startdate + diff, this.state.enddate + diff);
  }
  private handlePanLeft = () => { this.handlePan(true); }
  private handlePanRight = () => { this.handlePan(false); }

  //
  // Format X-axis ticks
  //
  private tickFormatter = (unixTime: number): string => {
    // Difference between end date and start date in seconds
    let diff = this.state.enddate - this.state.startdate;
    // If total number of seconds covered by chart is less than 3 days,
    // include hours/minutes in labels.
    if(diff <= 24 * 60 * 60 * 3) {
      return format(toUTC(unixTime), 'dd-MMM-yy HH:mm');
    } 
    // If total seconds covered by chart is more than 3 days, show 
    // only dates in labels.
    else {
      return format(toUTC(unixTime), 'dd-MMM-yy');
    }
  }

  private handleExport = () => {
    // Get width and height from chart wrapper div:
    let width = this.wrapperRef.clientWidth;
    let height = this.wrapperRef.clientHeight;
    // Find svg element in wrapper div:
    let svg = this.wrapperRef.children[0].children[0].children[0];

    // Create a temporary canvas:
    let canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    let ctx = canvas.getContext('2d');

    // Set background to white
    ctx.fillStyle = '#ffffff';
    ctx.fillRect(0, 0, width, height);

    // Serialize SVG, then use it as data for temporary img element:
    let xml = new XMLSerializer().serializeToString(svg);
    let dataUrl = 'data:image/svg+xml;utf8,' + encodeURIComponent(xml);
    let img = new Image(width, height);
    img.onload = () => {
      // When img loads SVG data, export its contents to PNG.      
      ctx.drawImage(img, 0, 0);
      let imageData = canvas.toDataURL('image/png', 1.0);
      saveAs(imageData, "chart.png");
    }
    img.src = dataUrl;    
  }

  render() {
    let p = this.props;

    // Calculate Y-axis max according to value type
    // show in chart:
    let max = 0;
    switch(this.state.valuetype.id) {
      case 'r': max = p.maxResult; break;
      case 'v': max = p.maxValue; break;
      case 'q': max = 10; break;
      case 't': max = 100; break;
      case 'p': max = 100; break;
    }

    return (
      <React.Fragment>
        <Section padded>
          <Flex stackable>
            <Flex.Row>
              <Flex.Column width={1}>
                <Form.Uncontrolled label="Plotted values" hint="Type of values to plot">
                  <Dropdown fluid data={ValueTypes} placeholder="Value type" value={this.state.valuetype} label={(item:IValueType) => item.name} onChange={this.handleChangeValuetype}>
                    <Dropdown.Column weight={1}>{(item:IValueType) => item.name}</Dropdown.Column>
                  </Dropdown>
                </Form.Uncontrolled>
              </Flex.Column>
              <Flex.Column width={1}>
                <Form.Uncontrolled label="Start date">
                  <Input type="date" fluid value={format(toUTC(this.state.startdate), 'yyyy-MM-dd')} onChange={this.handleChangeStartDate}/>
                </Form.Uncontrolled>
              </Flex.Column>
              <Flex.Column width={1}>
                <Form.Uncontrolled label="End date">
                  <Input type="date" fluid value={format(toUTC(this.state.enddate), 'yyyy-MM-dd')} onChange={this.handleChangeEndDate}/>
                </Form.Uncontrolled>
              </Flex.Column>
              <Flex.Column width={3}>
                <Form.Uncontrolled label="Zoom" hint="Zoom level time period">
                  <Button.Group size="small">
                    <Button noripple primary={this.state.period == 3} onClick={() => this.setPeriod(3)}>3d</Button>
                    <Button noripple primary={this.state.period == 7} onClick={() => this.setPeriod(7)}>1w</Button>
                    <Button noripple primary={this.state.period == 30} onClick={() => this.setPeriod(30)}>1m</Button>
                    <Button noripple primary={this.state.period == 90} onClick={() => this.setPeriod(90)}>3m</Button>
                    <Button noripple primary={this.state.period == 180} onClick={() => this.setPeriod(180)}>6m</Button>
                    <Button noripple primary={this.state.period == 365} onClick={() => this.setPeriod(365)}>1y</Button>
                  </Button.Group>
                </Form.Uncontrolled>
              </Flex.Column>
            </Flex.Row>
          </Flex>
        </Section>

        <div style={{height:'100%', position: 'relative'}} ref={(el:any) => this.wrapperRef = el}>
          <ResponsiveContainer width="100%" height="95%">
            <LineChart 
              data={this.state.points}
              margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
              onMouseDown={this.handleMouseDown}
              onMouseMove={this.handleMouseMove}
              onMouseUp={this.handleMouseUp}
              onMouseLeave={this.handleMouseLeave}
            >
              <CartesianGrid 
                strokeDasharray="6 3" 
                strokeWidth={0.5}
                stopOpacity={0.6}
              />              
              <XAxis 
                dataKey="d" 
                type='number' 
                scale='auto' 
                tickFormatter = {this.tickFormatter}
                tickCount={16}
                interval='preserveStart'
                domain={[this.state.startdate, this.state.enddate]}
                tick={{stroke:'#888', strokeWidth: 0.5, fontSize: 9, fontFamily: 'Roboto' }}
                allowDataOverflow={true}
              />
              <ReferenceLine y={max} stroke="green" strokeDasharray="3 3"/>
              {this.state.showTooltip && <Tooltip 
                content={<CustomTooltip unit={this.state.valuetype.unit}/>} 
                isAnimationActive={false}
              />}
              <YAxis 
                domain={[0, 1.1 * max]}
                type='number'
                scale='linear'
                unit={" " + this.state.valuetype.unit}
                tick={{stroke:'#888', strokeWidth: 0.5, fontSize: 9, fontFamily: 'Roboto' }}
              />
              <Line 
                dataKey={this.state.valuetype.id} 
                type={this.state.linetype.id}
                stroke={this.state.valuetype.color} 
                strokeWidth={2}
                dot={this.state.showDots}
                activeDot={{ stroke: this.state.valuetype.color, strokeOpacity: 0.3, strokeWidth: 12, fill: this.state.valuetype.color, r:3 }}
                isAnimationActive={false}
              />
              {this.state.refAreaLeft && this.state.refAreaRight && 
                <ReferenceArea 
                  x1={this.state.refAreaLeft} 
                  x2={this.state.refAreaRight} 
                  strokeOpacity={0.3} />}
            </LineChart>
          </ResponsiveContainer>
          <ZoomHolder>
            <ButtonGroup>
              <Button size="small" icon onClick={this.handlePanLeft}><Icon title="Pan left" name="chevron" mirrored/></Button>
              <Button size="small" icon onClick={this.handlePanRight}><Icon title="Pan right" name="chevron"/></Button>
              <Button size="small" icon onClick={this.handleZoomIn}><Icon title="Zoom in" name="zoom-plus"/></Button>
              <Button size="small" icon onClick={this.handleZoomOut}><Icon title="Zoom out" name="zoom-minus"/></Button>
              <Button size="small" icon onClick={() => this.setState({showPanel: true})}><Icon title="Options" name="tools"/></Button>
            </ButtonGroup>
            <Panel open={this.state.showPanel} onClose={() => this.setState({showPanel: false})}>
              <Panel.Header>
                Chart options
              </Panel.Header>
              <div style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '15px', paddingBottom: '15px'}}>
                <Form.Uncontrolled hint="Dots clearly show individual data points">
                  <Checkbox type="toggle" label="Show dots" checked={this.state.showDots} onChange={this.handleChangeShowDots}/>
                </Form.Uncontrolled>
                <Form.Uncontrolled label="Line type" hint="Chart line curvature">
                  <Dropdown data={LineTypes} placeholder="Line type" value={this.state.linetype} label={(item:ILineType) => item.name} onChange={this.handleChangeLinetype}>
                    <Dropdown.Column weight={1}>{(item:ILineType) => item.name}</Dropdown.Column>
                  </Dropdown>
                </Form.Uncontrolled>
                <Form.Uncontrolled label="Points" hint="More points allow for a more accurate chart, but take longer to download">
                  <Dropdown data={[250,500,1000,2000]} value={this.state.numPoints} label={(item: number) => item.toString()} onChange={this.handleChangeNumPoints}>
                    <Dropdown.Column weight={1}>{(item: number) => <React.Fragment>~<Number value={item} decimals={0}/></React.Fragment>}</Dropdown.Column>
                  </Dropdown>
                </Form.Uncontrolled>
              </div>
              <Divider fitted/>
              <Panel.Footer>
                <div style={{textAlign: 'right'}}>
                <Button size="tiny" secondary onClick={this.handleExport}>
                  <Label color="#000"><Icon color="#fff" name='file-download'/></Label>
                  Export as PNG
                </Button>
                </div>
              </Panel.Footer>
            </Panel>            
          </ZoomHolder>
        </div>

      </React.Fragment>
    )
  }
}



const ZoomHolder = styled('div')`
  position: absolute;
  right: 20px;
  top: 10px;
`

interface ITooltipProps {
  active?: boolean;
  payload?: any[];
  label?: number;
  unit: React.ReactText;
}

class CustomTooltip extends React.Component<ITooltipProps, {}> {

  render() {
    if(!this.props.active) return null;
    return (
      <div>
        <Segment raised tight secondary attached='top'>
        <div style={{fontSize: '80%'}}>{this.props.label ? format(toUTC(this.props.label), 'dd MMM yyyy @ HH:mm:ss') : '-'}</div>
        </Segment>
        <Segment raised tight attached='bottom'>
          {(this.props.payload && this.props.payload[0]) ? 
            <React.Fragment>
              <Number value={(this.props.payload[0] as any).value} decimals={2}/> {this.props.unit}
            </React.Fragment>
          : '-'}
        </Segment>
      </div>
    )
  }
}


export { DeviceChart, IPoint, IValueType, ILineType };
