interface ITimeZone {
  id: string;
  offset: number;
  name: string;
}

const TIMEZONES: ITimeZone[] = [
  { id: "-12:00", offset:  -12,  name: "International Date Line West" },
  { id: "-11:00", offset:  -11,  name: "Coordinated Universal Time-11" },
  { id: "-10:00", offset:  -10,  name: "Aleutian Islands, Hawaii" },
  { id: "-09:30", offset: -9.5,  name: "Marquesas Islands" },
  { id: "-09:00", offset:   -9,  name: "Alaska" },
  { id: "-08:00", offset:   -8,  name: "Baja California" },
  { id: "-07:00", offset:   -7,  name: "Arizona, Chihuahua, La Paz, Mazatlan" },
  { id: "-06:00", offset:   -6,  name: "Central America, Mexico City, Monterrey, Saskatchewan" },
  { id: "-05:00", offset:   -5,  name: "Bogotá, Lima, Quito, Haiti, Havana, Turks and Caicos" },
  { id: "-04:00", offset:   -4,  name: "Asuncion, Caracas, Cuiaba, Georgetown, San Juan, Santiago" },
  { id: "-03:30", offset: -3.5,  name: "Newfoundland" },
  { id: "-03:00", offset:   -3,  name: "Brasilia, Buenos Aires, Greenland, Montevideo, Salvador" },
  { id: "-02:00", offset:   -2,  name: "Coordinated Universal Time-2"},
  { id: "-01:00", offset:   -1,  name: "Azores, Cabo Verde" },
  { id: "+00:00", offset:    0,  name: "London, Lisbon, Reykjavik, São Tomé" },
  { id: "+01:00", offset:    1,  name: "Casablanca, Amsterdam, Berlin, Paris, West Central AFrica "},
  { id: "+02:00", offset:    2,  name: "Athens, Cairo, Harare, Helsinki, Windhoek" },
  { id: "+03:00", offset:    3,  name: "Baghdad, Moscow, Nairobi" },
  { id: "+03:30", offset:  3.5,  name: "Tehran" },
  { id: "+04:00", offset:    4,  name: "Abu Dhabi, Tbilisi, Volgograd" },
  { id: "+04:30", offset:  4.5,  name: "Kabul" },
  { id: "+05:00", offset:    5,  name: "Ashgabat, Islamabad, Karachi" },
  { id: "+05:30", offset:  5.5,  name: "Mumbai, New Delhi" },
  { id: "+05:45", offset: 5.75,  name: "Kathmandu" },
  { id: "+06:00", offset:    6,  name: "Astana, Dhaka, Omsk" }, 
  { id: "+06:30", offset:  6.5,  name: "Yangon (Rangoon)" },
  { id: "+07:00", offset:    7,  name: "Bangkok, Hanoi, Jakarta, Novosibirsk, Tomsk" },
  { id: "+08:00", offset:    8,  name: "Irkutsk, Kuala Lumpur, Singapore, Perth" },
  { id: "+08:45", offset: 8.75,  name: "Eucla" },
  { id: "+09:00", offset:    9,  name: "Chuta, Osaka, Tokyo, Pyongyang, Yakutsk" },
  { id: "+10:00", offset:   10,  name: "Brisbane, Canberra, Sydney, Guam, Vladivostok" },
  { id: "+10:30", offset: 10.5,  name: "Lord Howe Island" },
  { id: "+11:00", offset:   11,  name: "Bougainville Island, Magadan, Solomon Islands" },
  { id: "+12:00", offset:   12,  name: "Wellington, Fiji"},
  { id: "+12:45", offset:12.75,  name: "Chatham Islands" },
  { id: "+13:00", offset:   13,  name: "Nuku'alofa, Samoa" },
  { id: "+14:00", offset:   14,  name: "Kiritimati Island" }
];

export { ITimeZone, TIMEZONES };