import { Model, ResourceFactory } from '../services/';

class Reading extends Model {
  public datetime: string = '';
  public value: number = 0;
  public quality: number = 0;
  public temperature: number = 0;
  public created_by: number = 0;
  public result: number = 0;
  public percentage: number = 0;
}

let ReadingFactory = ResourceFactory.create<Reading>(Reading, 'reading');

export { Reading, ReadingFactory };