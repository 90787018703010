import { StampedModel, ResourceFactory } from '../services/';

class Country extends StampedModel {
  public name: string = '';
  public code: string = '';
  public projects_count: number = 0;
}

let CountryFactory = ResourceFactory.create<Country>(Country, 'country');

export { Country, CountryFactory };