import { Topbar } from './Topbar';
export { Topbar };

/** 
 * This index.ts file exists so that you can do
 *   
 *     import { Topbar } from '../modules/Topbar/';
 * 
 * and the required Topbar.tsx is automatically imported.
 */
