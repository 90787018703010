import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { css } from 'styled-components';
import axios from 'axios';

import { Container, Content, BottomBar, Section, Stats } from '../../modules';
import { RouteComponentProps } from 'react-router';
import { IAuthProps } from '../../services/Auth';

import { Number } from '@independent-software/typeui/formatters/Number';
import { App } from '../../App';
import { Loader } from '@independent-software/typeui/controls/Loader';
import { Online } from '../../controls/Online';
import { Link } from '../../controls/Link';

interface IState {
  data: {
    projects: number;
    projects_delta: number;
    beneficiaries: number;
    devices: number;
    devices_delta: number;
    devices_online: number;
    capacity: number;
    capacity_delta: number;
    storage: number;
    storage_percentage: number;
  }
}

class Dashboard extends React.Component<IAuthProps & RouteComponentProps<any>, IState> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.state = {
      data: null
    }
  }

  componentDidMount() {
    axios.get(`${App.apiURL}dashboard`, { params: {} })
    .then(response => {
      this.setState({
        data: response.data
      });
    })
    .catch(error => { });
  }

  handleProjectsClick = () => {
    this.props.history.push(`/projects`);
  }

  handleDevicesClick = () => {
    this.props.history.push(`/devices`);
  }

  render() {
    let p = this.props;
    return (
      <Container>
        {!this.state.data && <Loader/>}
        {this.state.data &&
        <Content>
          <Section padded>

            <Stats onClick={this.handleProjectsClick}>
              <Stats.Title><Link to={`/projects`}>Projects</Link></Stats.Title>
              <Stats.Sum value={this.state.data.projects_delta}/>
              <Stats.Center>
                <Stats.BigNumber><Number value={this.state.data.projects} decimals={0}/></Stats.BigNumber>
                projects
              </Stats.Center>
              <Stats.Bottom>
                <Number value={this.state.data.beneficiaries} decimals={0}/> beneficiaries
              </Stats.Bottom>
            </Stats>

            <Stats onClick={this.handleDevicesClick}>
              <Stats.Title><Link to={`/devices`}>Devices</Link></Stats.Title>
              <Stats.Sum value={this.state.data.devices_delta}/>
              <Stats.Center>
                <Stats.BigNumber><Number value={this.state.data.devices} decimals={0}/></Stats.BigNumber>
                devices
              </Stats.Center>
              <Stats.Bottom>
                <Online online={true} lastseen={null}/>
                <Number value={this.state.data.devices_online} decimals={0}/> currently online
              </Stats.Bottom>
            </Stats>            

            <Stats>
              <Stats.Title>Water storage</Stats.Title>
              <Stats.Sum value={this.state.data.capacity_delta}/>
              <Stats.Center>
                <Stats.BigNumber><Number value={this.state.data.storage} decimals={2}/></Stats.BigNumber>
                m<sup>3</sup>
              </Stats.Center>
              <Stats.Bottom>
                <Number value={this.state.data.storage_percentage} decimals={0}/>% of total capacity
              </Stats.Bottom>
            </Stats>      

          </Section>

        </Content>}
        {p.auth &&
          <BottomBar>
          </BottomBar>}
      </Container>
    );
  }
}

export { Dashboard };
