import * as React from 'react';
import { Container, Content, BottomBar } from '../../modules';
import { Devicetype } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListDevicetypes } from './ListDevicetypes';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class DevicetypeList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
    this.handleAdd = this.handleAdd.bind(this);    
    this.handleClick = this.handleClick.bind(this);
  }

  // Go to add devicetype view.
  handleAdd() {
    this.props.history.push('/devicetypes/add');
  }  

  // Go to view devicetype view.
  handleClick(item: Devicetype) {
    this.props.history.push(`/devicetypes/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListDevicetypes auth={p.auth} name="devicetypes" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth && p.auth.hasRight('can_edit_devicetypes') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> Add</Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { DevicetypeList };
