import * as React from 'react';
import { Auth } from '../../services/Auth';
import { RouteComponentProps } from 'react-router';

import { SignIn } from './SignIn';
import { SignUp } from './SignUp';
import { Button } from '@independent-software/typeui/controls/Button';
import { Dialog } from '@independent-software/typeui/controls/Dialog';

type TMode = 'signin' | 'signup';

interface ISignInUpProps {
  className?: string;
  /** Callback when signin is successful. */
  onSignin: (auth:Auth) => void;
  /** Callback when signup is successful. */
  onSignup: (auth:Auth) => void;
}

interface ISignInUpState {
  mode: TMode;
}

class SignInUp extends React.Component<ISignInUpProps, ISignInUpState> {
  constructor(props: ISignInUpProps & RouteComponentProps) {
    super(props);
    this.state = {
      mode: 'signin'
    };
  }

  handleMode = (mode: TMode) => {
    this.setState({
      mode: mode
    })
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        {this.state.mode == 'signin' && 
          <div>
            <Dialog.Header>Sign in</Dialog.Header>
            <Dialog.Content>
              <SignIn onSignin={p.onSignin} />
            </Dialog.Content>
            <Dialog.Footer>
              <div style={{textAlign:'center'}}>
                Don't have an account yet? <Button size="small" onClick={() => this.handleMode('signup')}>Sign up</Button>
              </div>
            </Dialog.Footer>
          </div>}
        {this.state.mode == 'signup' && 
          <div>
            <Dialog.Header>Sign up</Dialog.Header>
            <Dialog.Content>
              <SignUp onSignup={p.onSignup} />
            </Dialog.Content>
            <Dialog.Footer>
              <div style={{textAlign:'center'}}>
                Already have an account? <Button size="small" onClick={() => this.handleMode('signin')}>Sign in</Button>
              </div>
            </Dialog.Footer>            
          </div>}
      </div>
    )
  }
}

export { SignInUp };