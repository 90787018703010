import * as React from 'react';
import { IAuthProps } from '../../services/';
import { Reading } from '../../resource/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Message } from '@independent-software/typeui/controls/Message';
import { Divider } from '@independent-software/typeui/controls/Divider';



interface IProps {
  /** Initial form data. */
  data: Reading;
  /** Called whenever form changes. */
  onChange: (data: Reading, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Reading;
}

class ReadingForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
          <Message type="info">
            This screen allows you to manually correct a reading.
          </Message>
          <Divider hidden/>
          <Flex stackable>
            <Flex.Row>
              <Flex.Column width={1}>
                <Segment raised secondary attached="top">
                  <b>Reading</b>
                </Segment>
                <Segment raised attached="bottom">
                <Form.Field 
                  required={{message: "Value is required"}}
                  isFloat={{message: "Value must be a number."}}
                  width={1} label="Value"
                  name="value" 
                  value={this.state.data.value}
                  control={(<Input type="text" placeholder="Value" fluid/>)}
                  hint={(<React.Fragment>Please enter a value, e.g. <code>28</code>. The unit of measurement is automatic, depending on the device type.</React.Fragment>)}/>
                </Segment>
              </Flex.Column>
              <Flex.Column width={1}>
                <Segment raised secondary attached="top">
                  <b>Metadata</b>
                </Segment>
                <Segment raised attached="bottom">
                  <Form.Field 
                  required={{message: "Quality is required"}}
                  isInt={{message: "Quality must be a whole number."}}
                  min={{value: 0, message: "Quality must be at least 0."}}
                  max={{value: 10, message: "Quality must be at most 10."}}
                  width={1} label="Quality"
                  name="quality" 
                  value={this.state.data.quality}
                  control={(<Input type="text" placeholder="Quality" fluid/>)}
                  hint={(<React.Fragment>Please enter a quality value, e.g. <code>10</code>. Higher values indicate better reading quality.</React.Fragment>)}/>
                <Form.Field 
                  required={{message: "Temperature is required"}}
                  isInt={{message: "Temperature must be a whole number."}}
                  min={{value: -20, message: "Temperature must be at least -20."}}
                  max={{value: 50, message: "Temperature must be at most 50."}}
                  width={1} label="Temperature &ordm;C"
                  name="temperature" 
                  value={this.state.data.temperature}
                  control={(<Input type="text" placeholder="Temperature" fluid/>)}
                  hint={(<React.Fragment>Please enter a temperature in degrees Celcius, e.g. <code>22</code>.</React.Fragment>)}/>                
                </Segment>

              </Flex.Column>
            </Flex.Row>
          </Flex>


      </Form>
    )
  }
}

export { ReadingForm };
