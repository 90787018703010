import * as React from 'react';
import * as L from 'leaflet';
import { Device } from '../../resource/';
import { Map } from '../../modules/';
import { DevicetypeFactory, Devicetype } from '../../resource';
import { IAuthProps, Query } from '../../services/';

import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Message } from '@independent-software/typeui/controls/Message';
import { Tabs } from '@independent-software/typeui/controls/Tabs';

interface IProps {
  /** Initial form data. */
  data: Device;
  /** Project lag/lng */
  latitude: string;
  longitude: string;
  /** Called whenever form changes. */
  onChange: (data: Device, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Device;
  /** Data for Devicetypes dropdown */
  devicetypes: Devicetype[];  
}

class DeviceForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      devicetypes: []
    };    

    // Retrieve a list of profiles:
    DevicetypeFactory.getSome(this.props.auth, 0, 5, new Query('name', 'asc'))
      .then((res) => this.setState({ devicetypes: res.items }));    
  }

  handleDevicetypeSearch = (q:string) => {
    // Retrieve a list of Devicetypes:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    DevicetypeFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ devicetypes: res.items }));
  }  

  private handleMapClick = (latlng: L.LatLng) => {
    this.state.data.latitude = latlng.lat.toString();
    this.state.data.longitude = latlng.lng.toString();
    this.props.onChange(this.state.data, true);
  }

  render() {
    let p = this.props;
    // Use device lat/lng.
    let lat = parseFloat(this.state.data.latitude); 
    let lng = parseFloat(this.state.data.longitude); 
    // If device lat/lng not defined, use project lat/lng.
    if(isNaN(lat) || isNaN(lng)) {
      lat = parseFloat(p.latitude);
      lng = parseFloat(p.longitude);
    }
    // If lat/lng still not device, use null.
    let latlng = (isNaN(lat) || isNaN(lng)) ? null : L.latLng(lat, lng);
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Tabs underlined nohiddenrender>
          <Tabs.Pane label="Data">
            <Flex.Columns count={2}>
              <div>
                <Segment raised secondary attached="top">
                  <b>Device information</b>
                </Segment>            
                <Segment raised attached="bottom">
                  <Form.Field 
                    required={{message: "Device EUI is required."}}
                    minLength={{length: 16, message: "EUI must be exactly 16 (hexadecimal) digits."}}
                    maxLength={{length: 16, message: "EUI must be exactly 16 (hexadecimal) digits."}}
                    pattern={{pattern: '^[0-9a-fA-F]*$', message: "EUI must be a hexadecimal string." }}
                    width={1} label="Device EUI"
                    name="eui" 
                    value={this.state.data.eui}
                    control={(<Input type="text" placeholder="Device EUI" fluid/>)}
                    hint={(<React.Fragment>Please enter a device EUI, e.g. <code>244E7B0002000811</code>.</React.Fragment>)}/>

                  <Form.Field 
                    maxLength={{length: 30, message: "Name may not be longer than 30 characters."}}
                    width={1} label="Device name"
                    name="name" 
                    value={this.state.data.name}
                    control={(<Input type="text" placeholder="Device name" fluid/>)}
                    hint={(<React.Fragment>Please enter a device name, e.g. <code>Tank 35</code>.</React.Fragment>)}/>
                </Segment>
              </div>
              <div>
                <Segment raised secondary attached="top">
                  <b>Device type</b>
                </Segment>            
                <Segment raised attached="bottom">
                  <Form.Field
                    forceupdate
                    required={{message: "A device type is required."}}
                    width={1} label="Device type"
                    name="devicetype" 
                    value={this.state.data.devicetype}
                    control={(<Dropdown onSearch={this.handleDevicetypeSearch} fluid data={this.state.devicetypes} placeholder="Device type" label={(item:Devicetype) => item.name}>
                      <Dropdown.Column weight={1}>{(item:Devicetype) => item.name}</Dropdown.Column>
                    </Dropdown>)}
                    hint="Please select a device type."/>

                  {this.state.data.devicetype && this.state.data.devicetype.type == 'level' && <React.Fragment>

                    <Form.Field
                      width={1} label="Tank volume"
                      isFloat={{message: "This must be a numeric value."}}
                      min={{value: 0, message: "This must be a positive value."}}
                      name="param1"
                      value={this.state.data.param1}
                      control={(<Input type="text" placeholder="Tank volume" fluid/>)}
                      hint={(<React.Fragment>Please enter the tank volume in m3, e.g. <code>4.0</code></React.Fragment>)}/>

                    <Form.Field
                      width={1} label="Tank height"
                      isFloat={{message: "This must be a numeric value."}}
                      min={{value: 0, message: "This must be a positive value."}}
                      name="param2"
                      value={this.state.data.param2}
                      control={(<Input type="text" placeholder="Tank height" fluid/>)}
                      hint={(<React.Fragment>Please enter the tank height in cm, e.g. <code>240</code></React.Fragment>)}/>

                    <Form.Field
                      width={1} label="Dead zone"
                      isFloat={{message: "This must be a numeric value."}}
                      min={{value: 0, message: "This must be a positive value."}}
                      name="param3"
                      value={this.state.data.param3}
                      control={(<Input type="text" placeholder="Dead zone" fluid/>)}
                      hint={(<React.Fragment>Please enter the tank dead zone in cm, e.g. <code>15</code></React.Fragment>)}/>                  
                  
                  </React.Fragment>}

                </Segment>

              </div>
            </Flex.Columns>
          </Tabs.Pane>
          <Tabs.Pane label="Location">
            <Flex.Columns count={2}>
              <div>
                <Segment raised secondary attached="top">
                  <b>Description</b>
                </Segment>            
                <Segment raised attached="bottom">
                  <Form.Field 
                    width={1} label="Description"
                    name="description" 
                    value={this.state.data.description}
                    control={(<MarkdownTextarea rows={10} placeholder="Description" fluid/>)}/>
                </Segment>
              </div>
              <div>
                <Segment raised secondary attached="top">
                  <b>Device location</b>
                </Segment>
                <Segment raised attached="bottom">
                  <Map point={latlng} zoom={12} onClick={this.handleMapClick}/>
                  <Divider hidden/>
                  <Message type="info">
                    Set a location by clicking the map, or type in a latitude/longitude below.
                  </Message>
                  <Flex.Columns count={2}>
                    <Form.Field 
                      forceupdate
                      required={{message: "Latitude is required."}}
                      isFloat={{message: "Latitude must be a number."}}
                      width={1} label="Latitude"
                      name="latitude" 
                      value={this.state.data.latitude}
                      control={(<Input type="text" placeholder="Latitude" fluid/>)}
                      hint={(<React.Fragment>Please enter a latitude, e.g. <code>24.3</code>.</React.Fragment>)}/>
                    <Form.Field 
                      forceupdate
                      required={{message: "Longitude is required."}}
                      isFloat={{message: "Longitude must be a number."}}
                      width={1} label="Longitude"
                      name="longitude" 
                      value={this.state.data.longitude}
                      control={(<Input type="text" placeholder="Longitude" fluid/>)}
                      hint={(<React.Fragment>Please enter a longitude, e.g. <code>32.4</code>.</React.Fragment>)}/>
                  </Flex.Columns>
                </Segment>
              </div>
            </Flex.Columns>

          </Tabs.Pane>

        </Tabs>
      </Form>
    )
  }
}

export { DeviceForm };
