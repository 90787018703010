//const apiURL = 'http://wash-api.independent-software.com';
//const apiURL = 'http://api.wash';
// (This is now in config.json)

const MAP = {
  // Standard OSM: url: 'http://{s}.tile.osm.org/{z}/{x}/{y}.png',
  url: 'https://b.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png',
  attribution: '&copy; Humanitarian OpenStreetMap Team'
}

/**
 * Rights available in application.
 * 
 * WARNING WARNING: When you add a right here, also add it to resource/Profile.ts,
 * as well as to API:Rights.php !!!
 */
type TRight = 
    'can_view_users' 
  | 'can_edit_users'
  | 'can_view_profiles'
  | 'can_edit_profiles'
  | 'can_view_logs'
  | 'can_view_countries'
  | 'can_edit_countries'
  | 'can_edit_projects'
  | 'can_view_devicetypes'
  | 'can_edit_devicetypes'
  | 'can_edit_devices'
  // WARNING WARNING WARNING see above.

interface IAccessGroup {
  title: string;
  rights: IRight[];
}

interface IRight {
  title: string;
  name: TRight;
}

let accessGroups: IAccessGroup[] = [
  {
    title: 'User management',
    rights: [
      { title: 'View users', name: 'can_view_users' },
      { title: 'Edit users', name: 'can_edit_users' }
    ]
  }, {
    title: 'Profile management',
    rights: [
      { title: 'View profiles', name: 'can_view_profiles' },
      { title: 'Edit profiles', name: 'can_edit_profiles' }
    ]
  }, {
    title: 'Log management',
    rights: [
      { title: 'View logs', name: 'can_view_logs'}
    ]
  }, {
    title: 'Country management',
    rights: [
      { title: 'View countries', name: 'can_view_countries'},
      { title: 'Edit countries', name: 'can_edit_countries'}
    ]
  }, {
    title: 'Project management',
    rights: [
      { title: 'Edit all projects', name: 'can_edit_projects'}
    ]
  }, {
    title: 'Device types',
    rights: [
      { title: 'View device types', name: 'can_view_devicetypes'},
      { title: 'Edit device types', name: 'can_edit_devicetypes'}
    ]
  }, {
    title: 'Device management',
    rights: [
      { title: 'Edit devices', name: 'can_edit_devices'}
    ]
  }  
];

export { TRight, IAccessGroup, IRight, accessGroups, MAP };