import * as React from 'react';
import { Input } from '@independent-software/typeui/controls/Input';
import { Form } from '@independent-software/typeui/controls/Form';
import { IAccount } from './IAccount';
import { Password } from '@independent-software/typeui/modules/Password';

interface IProps {
  /** Initial form data. */
  data: IAccount;
  /** Called whenever form changes. */
  onChange: (data: IAccount, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: IAccount;
}

class AccountForm extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <Form data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Form.Group equal>
          <Form.Field 
            required={{message: "Name is required."}}
            minLength={{length: 3, message: "Name must be at least 3 characters"}}
            maxLength={{length: 30, message: "Name may not be longer than 30 characters"}}            
            width={1} label="Username"
            name="name" 
            value={this.state.data.name}
            control={(<Input type="text" placeholder="Name" fluid/>)}
            hint={(<React.Fragment>Please enter a username, e.g. <code>john.smith</code></React.Fragment>)}/>
          <Form.Field 
            required={{message: "Email is required"}}
            email={{message: "Email must be a valid email address"}}
            width={1} label="Email"
            name="email" 
            value={this.state.data.email}
            control={(<Input type="text" placeholder="Email" fluid/>)}
            hint={(<React.Fragment>Please enter the user's email address, e.g. <code>john.smith@gmail.com</code></React.Fragment>)}/>
        </Form.Group>
        <Form.Group>
          <Form.Field
            required={{message: "You must enter your password."}}
            width={1} label="Password"
            name="password" 
            value={this.state.data.password}
            control={(<Password fluid placeholder="Password"/>)}
            hint="Please enter your password."/>
          <Form.Field
            minLength={{length: 8, message: "Your new password must have at least 8 characters."}}
            maxLength={{length: 30, message: "Your new password must have no more than 30 characters."}}
            noPattern={{pattern: '^(.{0,7}|.{31,}|[^0-9]*|[^A-Za-z]*|[a-zA-Z0-9]*)$', message: "Your new password must contain letters, digits and at least one special character."}}            
            width={1} label="New password"
            name="newpassword" 
            value={this.state.data.newpassword}
            control={(<Password fluid placeholder="New password"/>)}
            hint="Enter a new password if desired."/>
        </Form.Group>            
      </Form>
    )
  }
}

export { AccountForm };
