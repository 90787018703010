import * as React from 'react';
import axios from 'axios';
import styled from '@independent-software/typeui/styles/Theme';
import * as L from 'leaflet';
import { RouteComponentProps } from 'react-router';
import { Device, DeviceFactory, Reading } from '../../resource/'; 
import { IAuthProps } from '../../services/Auth';

import { Markdown} from '@independent-software/typeui/formatters/Markdown';
import { Tabs } from '@independent-software/typeui/controls/Tabs';
import { Header } from '@independent-software/typeui/controls/Header';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { Table } from '@independent-software/typeui/controls/Table';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Number } from '@independent-software/typeui/formatters/Number';
import { Label } from '@independent-software/typeui/controls/Label';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Progress } from '@independent-software/typeui/controls/Progress';

import { Eui, Gps, NA } from '../../formatters';
import { ListReadings } from '../Reading';
import { Section, Viewer } from '../../modules';
import { Map } from '../../modules/';
import { Link } from '../../controls/Link';
import { ValueStore } from '../../services';

import { formatDistanceToNow, parse } from 'date-fns';
import { Online } from '../../controls/Online';
import { DeviceChart } from './DeviceChart';
import { App } from '../../App';
import { Message } from '@independent-software/typeui/controls/Message';
import { Divider } from '@independent-software/typeui/controls/Divider';

interface IState {
  timestamp?: number;
  showClearReadings: boolean;
  confirm: boolean;
}

class ViewDevice extends React.Component<IAuthProps & RouteComponentProps<{id:string}>, IState> {
  private device_id: number;

  constructor(props: IAuthProps & RouteComponentProps<{id:string}>) {
    super(props);
    this.device_id = parseInt(this.props.match.params.id);
    this.state = {
      showClearReadings: ValueStore.get("view_device", 0) == 2,
      confirm: false
    }
  }

  private canEdit = (device: Device): boolean => {
    return this.props.auth && ((device.created_by != null && device.created_by.id == this.props.auth.id)
          || this.props.auth.hasRight('can_edit_devices'));
  }

  private handleEdit = () => {
    this.props.history.push(`/devices/edit/${parseInt(this.props.match.params.id)}`);
  }

  private handleDelete = () => {
    ToastService.toast("Device deleted");
    this.props.history.goBack();
  }    

  private handleReadingClick = (item:Reading) => {
    this.props.history.push(`/readings/edit/${item.id}`);
  }     

  private handleTabChange = (index: number) => {
    ValueStore.set("view_device", index);
    this.setState({
      showClearReadings: index == 3
    })
  }  

  private clearReadingsButton = (device: Device): React.ReactNode => {
    if(this.state.showClearReadings && this.canEdit(device)) {
      return <Button secondary onClick={() => this.setState({ confirm: true })}><Icon name="trash"/> Clear readings</Button>
    }
    return null;
  }

  private handleClearReadings = () => {
    // Hide delete all window
    this.setState({ confirm: false });
    // Call API to delete all
    axios.delete(`${App.apiURL}device-clear/${this.device_id}?api_token=${this.props.auth.token}`)
    .then(response => {
      // Force reload of Viewer
      this.setState({ timestamp: Math.random() });
    });
  }

  private copyToClipboard = (text: string): void => {
    if ((window as any).clipboardData && (window as any).clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return (window as any).clipboardData.setData("Text", );
    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
      var textarea = document.createElement("textarea");
      textarea.textContent = text;
      textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand("copy");  // Security exception may be thrown by some browsers.
      }
      catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
      }
      finally {
        document.body.removeChild(textarea);
      }
    }    
  }

  render() {
    let p = this.props;
    return (
      <React.Fragment>
        <Viewer<Device> 
          auth={p.auth}
          id={this.device_id}
          timestamp={this.state.timestamp}
          factory={DeviceFactory}
          canEdit={this.canEdit}
          onEdit={this.handleEdit}
          onDelete={this.handleDelete}
          deleteConfirmation={<React.Fragment>Are you sure you wish to permanently delete <b>THIS DEVICE AND ALL ITS READINGS</b> from the database?</React.Fragment>}
          custom={(device) => this.clearReadingsButton(device)}
          content={(device: Device) => {
            // Get lat/lng from device. If not set, default to null (in Leaflet format).
            let lat = parseFloat(device.latitude); 
            let lng = parseFloat(device.longitude); 
            let latlng = (isNaN(lat) || isNaN(lng)) ? null : L.latLng(lat, lng);          

            // Calculate result percentage:
            let percentage = device.result ? (device.maxResult == 0 ? 0 : 100 * device.result / device.maxResult) : null;

            return(<React.Fragment>
              <Section dark padded>
                <div style={{float: 'right', marginTop: '10px'}}><Label><Eui value={device.eui}/> <Icon color="#666" url="sprites.svg#clipboard" title="Copy to clipboard" onClick={() => this.copyToClipboard(device.eui)}/></Label></div>
                <Header size="h3">{device.name} @&nbsp;<Link to={`/projects/${device.project.id}`}>{device.project.name}</Link></Header>
              </Section>
              <Tabs underlined nohiddenrender active={ValueStore.get('view_device', 0)} onTabChange={this.handleTabChange}>
                <Tabs.Pane label="Overview">
                  <Section padded>
                    <Flex.Columns count={2}>
                      <div>
                        <Segment secondary raised attached="top">
                          <b>Status</b>
                        </Segment>
                        <Segment raised attached="bottom">
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td>Online</td>
                                <td style={{textAlign: 'right'}}>
                                  <Online online={device.isOnline()} lastseen={device.lastseen}/>
                                  {device.lastseen ? <span title={device.lastseen}>{formatDistanceToNow(parse(device.lastseen.toString(), 'yyyy-MM-dd HH:mm:ss', new Date())) + ' ago'}</span> : 'never'}
                                </td>
                              </tr>
                              <tr>
                                <td>Last reading</td>
                                <td style={{textAlign: 'right'}}>{device.value ? <React.Fragment><Number value={device.value} decimals={2}/> cm</React.Fragment> : <NA/>}</td>
                              </tr>
                              <tr>
                                <td>Last value</td>
                                <td style={{textAlign: 'right'}}>{device.result ? <React.Fragment><Number value={device.result} decimals={2}/> m<sup>3</sup></React.Fragment> : <NA/>}</td>
                              </tr>
                              <tr>
                                <td>Percentage</td>
                                <td style={{textAlign: 'right'}}>{percentage !== null ? 
                                  <Progress color={percentage <= 10 ? '#c00' : null} numbered background value={percentage}/> : <NA/>}
                                </td>
                              </tr>
                              <tr>
                                <td>Battery</td>
                                <td style={{textAlign: 'right'}}>{device.battery ? <React.Fragment><Number value={device.battery} decimals={2}/>%</React.Fragment> : <NA/>}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </Segment>
                      </div>
                      <div>
                        <Segment secondary raised attached="top">
                          <b>History</b>
                        </Segment>
                        <Segment raised attached="bottom">
                          <Flex.Quick>
                            <Icon size="big" url="sprites.svg#24h" title="24h"/>
                            <div>
                              <Table transparent>
                                <tbody>
                                  <tr>
                                    <td>Max</td>
                                    <td style={{textAlign: 'right'}}>{device.max24h ? <React.Fragment><Number value={device.max24h} decimals={2}/> m<sup>3</sup> (<Number value={device.maxResult == 0 ? 0 : 100 * device.max24h/device.maxResult} decimals={0}/>%)</React.Fragment> : <NA/>}</td>
                                  </tr>
                                  <tr>
                                    <td>Mean</td>
                                    <td style={{textAlign: 'right'}}>{device.avg24h ? <React.Fragment><Number value={device.avg24h} decimals={2}/> m<sup>3</sup> (<Number value={device.maxResult == 0 ? 0 : 100 * device.avg24h/device.maxResult} decimals={0}/>%)</React.Fragment> : <NA/>}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Flex.Quick>
                          <Divider hidden/>
                          <Flex.Quick>
                            <Icon size="big" url="sprites.svg#30d" title="30d"/>
                            <div>
                              <Table transparent>
                                <tbody>
                                  <tr>
                                    <td>Max</td>
                                    <td style={{textAlign: 'right'}}>{device.max30d ? <React.Fragment><Number value={device.max30d} decimals={2}/> m<sup>3</sup> (<Number value={device.maxResult == 0 ? 0 : 100 * device.max30d/device.maxResult} decimals={0}/>%)</React.Fragment> : <NA/>}</td>
                                  </tr>
                                  <tr>
                                    <td>Mean</td>
                                    <td style={{textAlign: 'right'}}>{device.avg30d ? <React.Fragment><Number value={device.avg30d} decimals={2}/> m<sup>3</sup> (<Number value={device.maxResult == 0 ? 0 : 100 * device.avg30d/device.maxResult} decimals={0}/>%)</React.Fragment> : <NA/>}</td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Flex.Quick>
                        </Segment>
                      </div>
                    </Flex.Columns>
                  </Section>
                </Tabs.Pane>
                <Tabs.Pane label="Device data">
                  <Section padded>
                    <Flex.Columns count={2}>
                      <div>
                        <Segment raised secondary attached="top">
                          <b>Device information</b>
                        </Segment>
                        <Segment raised attached="bottom">
                          <Table striped transparent>
                            <tbody>
                              <tr>
                                <td>Device name</td>
                                <td>{device.name}</td>
                              </tr>                          
                              <tr>
                                <td>Project</td>
                                <td><Link to={`/projects/${device.project.id}`}>{device.project.name}</Link></td>
                              </tr>                          
                              <tr>
                                <td>Device type</td>
                                <td><Link to={`/devicetypes/${device.devicetype.id}`}>{device.devicetype.name}</Link></td>
                              </tr>
                            </tbody>
                          </Table>
                        </Segment>

                        {device.devicetype.type == 'level' && 
                        <React.Fragment>
                          <Segment secondary raised attached="top">
                            <b>Tank dimensions</b>
                          </Segment>
                          <Segment raised attached="bottom">
                            <Table striped transparent>
                              <tbody>
                                <tr>
                                  <td>Tank volume</td>
                                  <td style={{textAlign: 'right'}}>{device.param1 ? <React.Fragment><Number value={device.param1} decimals={2}/> m<sup>3</sup></React.Fragment> : '-'} </td>
                                </tr>
                                <tr>
                                  <td>Tank height</td>
                                  <td style={{textAlign: 'right'}}>{device.param2 ? <React.Fragment><Number value={device.param2} decimals={2}/> cm</React.Fragment> : '-'} </td>
                                </tr>
                                <tr>
                                  <td>Tank dead zone</td>
                                  <td style={{textAlign: 'right'}}>{device.param3 ? <React.Fragment><Number value={device.param3} decimals={2}/> cm</React.Fragment> : '-'} </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Segment>

                        </React.Fragment>}
                      </div>
                      <div>
                        <Segment raised secondary attached="top">
                          <b>Device description</b>
                        </Segment>
                        <Segment raised attached="bottom">
                          {device.description && <Markdown source={device.description}/>}
                          {!device.description && <p>There is no device description.</p>}
                        </Segment>                      
                      </div>
                    </Flex.Columns>
                  </Section>
                </Tabs.Pane>
                <Tabs.Pane label="Map">
                  <Map point={latlng} zoom={12}/>
                  <GpsOverlay>
                    <Gps latitude={device.latitude} longitude={device.longitude}/>
                  </GpsOverlay>
                </Tabs.Pane>
                <Tabs.Pane label={<span>Readings <Label><Number value={device.readings_count} decimals={0}/></Label></span>}>
                  <ListReadings auth={p.auth} name="device_readings" deviceId={device.id} onClick={this.handleReadingClick}/>
                </Tabs.Pane>
                <Tabs.Pane label="Chart">
                  <DeviceChart device_id={this.device_id} maxValue={device.maxValue} maxResult={device.maxResult}/>
                </Tabs.Pane> 
              </Tabs>
            </React.Fragment>)}}>
        </Viewer>
        <Dialog.Confirm open={this.state.confirm} onClose={() => this.setState({confirm: false})} onConfirm={this.handleClearReadings}>
          Are you sure you wish to delete <b>ALL READINGS</b> for this device permanently from the database?
        </Dialog.Confirm>
      </React.Fragment>
    );
  }
}

// Overlays content on top of map
const GpsOverlay = styled('div')`
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 999;
`;

export { ViewDevice };
