import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { Icon } from '@independent-software/typeui/controls/Icon';

interface IProps {
  /** Battery percentage */
  level: number;
}

class BatteryLevel extends React.Component<IProps, {}> {
  render() {
    let p = this.props;

    // Convert level% to icon name and color.
    let name;
    let color;
    if(p.level <= 0) { name = "0"; color = 'red'; }
    else if(p.level <= 30) { name = "25"; color = "darkred"; }
    else if(p.level <= 60) { name = "50"; color = "darkgreen"; }
    else if(p.level <= 85) { name = "85"; color = "darkgreen"; }
    else { name = "100"; color = "green"; }

    return (
      <Icon url={"sprites.svg#battery-" + name} color={color} title={p.level + '%'}/>
    );
  }
}

export { BatteryLevel };
