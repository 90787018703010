import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { App } from './App'
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { StyleReset, StyleBase, Theme } from '@independent-software/typeui/styles'
import styledLeaflet from 'styled-leaflet'
import { AppContainer } from './AppContainer'
import { LeafletCustom } from './LeafletCustom'
import { RechartsCustom } from './RechartsCustom'

const StyledLeaflet = createGlobalStyle`
  ${styledLeaflet}
`

App.run(() => 
  ReactDOM.render(
    (<React.Fragment>
      <StyleReset/>
      <StyleBase/>
      <StyledLeaflet/>
      <LeafletCustom/>
      <RechartsCustom/>
      <ThemeProvider theme={Theme}>
        <AppContainer/>
      </ThemeProvider>
    </React.Fragment>),
    document.getElementById('root')
  ));

// Whenever webpack rebuilds the project, refresh the browser.
declare let module: any;
if (module.hot) {
  module.hot.accept(); 
}
