import { Sidebar } from './Sidebar';
export { Sidebar };

/** 
 * This index.ts file exists so that you can do
 *   
 *     import { Sidebar } from '../controls/Sidebar/';
 * 
 * and the required Sidebar.tsx is automatically imported.
 */
