import { CurrentUser } from './CurrentUser';
export { CurrentUser };

/** 
 * This index.ts file exists so that you can do
 *   
 *     import { CurrentUser } from '../modules/CurrentUser/';
 * 
 * and the required CurrentUser.tsx is automatically imported.
 */
