import * as React from 'react';
import { Image } from '@independent-software/typeui/controls/Image';
import { Size } from '@independent-software/typeui/controls/Types';

interface IProps {
  /** Country code */
  code: string;
  /** Country name */
  name: string;
  /** Flag size (defaults to 'mini') */
  size?: Size;
}

class Flag extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    let size = p.size ? p.size : 'mini';
    return (
      <Image src={`https://www.countryflags.io/${p.code}/shiny/64.png`} avatar inline alt={p.name} title={p.name} size={size}/>
    );
  }
}

export { Flag };
