import * as React from 'react';
import { TOperator, TDir, Query } from './Query';
import { Resource } from './Resource';
import { IAuthProps } from './Auth';
import { ValueStore } from './ValueStore';
import { QueryStore } from './QueryStore';

interface IListProps extends IAuthProps {
  name: string;
}

/**
 * By default, a list has an array of items and a Query.
 * Inheriting classes may add more properties.
 */
interface IListState<T> {
  items: T[];
  query: Query;
  loading?: boolean;
  error?: boolean;
  // An error occorred during export? (Use this to show a dialog in inheriting class).
  exportError?: any;
  // Table scrolltop
  scrollTop: number;  
}

/**
 * A List has a Resource type, e.g. <User>, as well as a state which must
 * extend IListState<User>.
 */
class List<T, P extends IListProps, S extends IListState<T>> extends React.Component<P, S> {
  private resource: Resource<T>;
  protected query: Query;

  constructor(props: P, resource: Resource<T>, order: string, dir: TDir) {
    super(props);
    this.resource = resource;

    this.query = QueryStore.get(props.name, order, dir);
    this.state = {
      items: [] as (any[]),
      query: this.query,
      scrollTop: ValueStore.get(this.props.name, 0)
    } as S;
  }

  // 
  // DataTable sends callbacks with list parts to be retrieved from the
  // data source.
  // 
  public handleFetch = (offset: number, count: number) => {
    this.fetch(this.state.query, offset, count);
  }

  //
  // Retrieve additional list data from the data source.
  // 
  private fetch(query: Query, offset: number, count: number) {
    this.setState({
      loading: true, 
      error: false 
    });
    this.resource.getSome(this.props.auth, offset, count, query)
      .then(res => {
        this.setState({loading: false});
        this.setState((prevState) => {
          // If there are currently no items, start a new list.
          if(prevState.items.length === 0) {
            let items = res.items;
            items = items.concat(Array(res.count - items.length).fill(null));
            return {
              ...prevState,
              items: items
            }
          } 
          // If there are some items, then place them into the existing
          // list starting at <offset>.
          else {
            for(let i = 0; i < Math.min(count, res.items.length); i++) {
              prevState.items[i+offset] = res.items[i];
            }
            return {
              ...prevState,
              items: prevState.items
            }
          }
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          error: true
        });
      });    
  }

  public handleOrder = (order: string, dir?: TDir) => {
    let q = this.state.query;

    // If the order column did not change, then toggle the sort dir.
    if(q.order === order) {
      q.dir = q.dir === 'asc' ? 'desc' : 'asc';
    }

    // If the order column *did* change, then use provided default dir.
    // If default dir is not provided, then default to 'asc'.
    if(q.order !== order) {
      q.order = order;
      q.dir = dir ? dir : 'asc';
    }
    
    this.setState({
      items: ([] as any),
      query: q
    });

    this.fetch(q, 0, 25);
  }

  /**
   * Return current order.
   */
  getOrder = (): string => {
    return this.state.query.order;
  }

  /**
   * Return current order dir.
   */
  getDir = (): TDir => {
    return this.state.query.dir;
  }

  handleScroll = (scrollTop: number) => {
    // Store scrollTop in ValueStore and state.
    ValueStore.set(this.props.name, scrollTop);
    this.setState({
      scrollTop: scrollTop
    });
  }  

  private export(format: string, query: Query) {
    this.setState({
      loading: true, 
      exportError: null 
    });
    this.resource.export(this.props.auth, format, query)
      .then(() => {
        this.setState({loading: false});
      })
      .catch((error) => {
        this.setState({
          loading: false,
          exportError: error
        });
      });    
  }  

  protected handleExport = (format: string) => {
    this.export(format, this.state.query);
  }  

  protected handleCloseDialog = () => {
    this.setState({
      exportError: null
    });
  }

  public hasFilter = (key: string, operator: TOperator): boolean => {
    return this.state.query.hasFilter(key, operator);
  }

  public getFilter = (key: string, operator: TOperator): any => {
    return this.state.query.getFilter(key, operator);
  }

  /**
   * Sets a filter on the Query.
   * @param key Filter key (a name)
   * @param operator Operator, e.g. 'eq' or 'lte' or 'like'
   * @param value Value to compare against
   * @param noRefresh If true, data is not fetched on filter change. This is useful on initialization of table data.
   */
  public setFilter(key: string, operator: TOperator, value:any, noRefresh?: boolean) {
    this.state.query.setFilter(key, operator, value);
    if(!(noRefresh === true)) {
      this.fetch(this.state.query, 0, 25);
      this.setState((prevState) => {
        // Create a new state object. 
        let state: S = {
          ...prevState,
          items: [],
          query: prevState.query
        };
        return state;      
      });  
    }
  }
}

export { IListProps, IListState, List };
