import { StampedModel, ResourceFactory } from '../services/';

class Profile extends StampedModel {
  public name: string = '';
  public is_default: boolean = false;
  public users_count: number = 0;

  // Rights:
  public can_view_users = false;
  public can_edit_users = false;
  public can_view_profiles = false;
  public can_edit_profiles = false;
  public can_view_logs = false;
  public can_view_countries = false;
  public can_edit_countries = false;
  public can_edit_projects = false;
  public can_view_devicetypes = false;
  public can_edit_devicetypes = false;
  public can_edit_devices = false;
}

let ProfileFactory = ResourceFactory.create<Profile>(Profile, 'profile');

export { Profile, ProfileFactory };