import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

class IconBarBase extends React.Component<IProps, {}> {
  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        {p.children}
      </div>
    )
  }
}

const IconBar = styled(IconBarBase)`
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 20px;
  & > * {
    margin-left: 8px;
  }
`

export { IconBar };