import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme';

interface INAProps {
  className?: string;
}

class NABase extends React.Component<INAProps, {}> {
  render() {
    return (<span className={this.props.className}>&mdash;</span>);
  }
}

const NA = styled(NABase)`
  color: #ccc;
`

export { NA };
