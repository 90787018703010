import * as React from 'react';
import 'leaflet';
import 'leaflet.markercluster';
const L = (window as any).L;
import styled from '@independent-software/typeui/styles/Theme'
import { darken } from '@independent-software/typeui/helper/darken';
import { Device } from '../../resource';
import { MAP } from '../../config/Config';

interface IMapProps {
  className?: string;
  children?: React.ReactNode;
  point: L.LatLng;
  devices: Device[];
  zoom?: number;
}

class DevicesMapBase extends React.Component<IMapProps, {}> {
  private mapElement: HTMLDivElement;
  private map: L.Map;
  private cluster: L.MarkerClusterGroup;

  componentDidMount() {
    this.map = L.map(this.mapElement, {
      center: this.props.point,
      zoom: this.props.zoom ? this.props.zoom : 3,
      zoomControl: false,
      layers: [
        L.tileLayer(MAP.url, {
          attribution: MAP.attribution
        }),
      ]
    });

    // Create cluster group for markers:
    this.cluster = L.markerClusterGroup({
      iconCreateFunction: function(cluster: L.MarkerCluster) {
        let n = cluster.getAllChildMarkers().length;
        return L.divIcon({ html: n, className: 'cluster', iconSize: L.point(32,32)})
      }
    });
    this.map.addLayer(this.cluster);

    // Add zoom control at bottom right:
    L.control.zoom({ position: 'bottomright' }).addTo(this.map);

    // Add project marker
    this.createProjectMarker();

    this.addDevices();
  }

  componentDidUpdate() {
    this.removeDevices();
    this.addDevices();
  }

  private createProjectMarker = () => {
    let circleOptions = {
      radius: 5, 
      fillColor: 'slateblue', 
      color: 'darkblue', 
      weight: 1
    };
    L.circleMarker(this.props.point, circleOptions)
      .addTo(this.map)
      .bindPopup("Project location");
  }  

  private removeDevices = () => {
    this.cluster.clearLayers();
  }

  private addDevices = () => {
    this.props.devices.forEach((device) => {
      this.addDevice(device);
    });
    this.zoomToMarkers();
  }

  private formatEUI = (eui:string) => {
    return eui.substr(0,4) 
           + '.' + eui.substr(4,4)
           + '.' + eui.substr(8,4)
           + '.' + eui.substr(12,4);
  }

  private addDevice = (device: Device) => {
    let fillColor = device.isOnline() ? '#76FD86' : '#FDAA87';
    let strokeColor = darken(0.5, fillColor);
    let circleOptions = {
      radius: 6, 
      fillColor: fillColor, 
      color: strokeColor, 
      weight: 1,
      fill: true,
      fillOpacity: 1
    };
    let marker: L.CircleMarker = L.circleMarker([parseFloat(device.latitude), parseFloat(device.longitude)], circleOptions);
    marker.bindPopup(
      `<table class="popup">
        <tbody>
          <tr><td>EUI</td><td style="font-weight:bold"><a href="#/devices/${device.id}">${this.formatEUI(device.eui)}</a></td></tr>
          <tr><td>Name</td><td>${device.name}</td></tr>
          <tr><td>Type</td><td>${device.devicetype.name}</td></tr>
          <tr><td>Readings</td><td>${device.readings_count ? device.readings_count.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</td></tr>
          <tr><td>Last result</td><td>${device.result ? device.result.toLocaleString(undefined, { useGrouping: true, minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '<span style="color:#888">&mdash;</span>'}</td></tr>
        </tbody>
      </table>
      `);

    this.cluster.addLayer(marker);
  }

  private zoomToMarkers = () => {
    let bounds = this.cluster.getBounds();
    if(!bounds.isValid()) return;
    this.map.fitBounds(this.cluster.getBounds(), { animate: false });
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className} ref={(el:any) => this.mapElement = el}>{p.children}</div>
    );
  }
}

const DevicesMapStyled = styled(DevicesMapBase)`
  width: 100%;
  height: 100%;
`;

class DevicesMap extends React.Component<IMapProps, {}> {
  public static displayName = "DevicesMap";

  render() {
    let p = this.props;
    return (
      <DevicesMapStyled {...p}></DevicesMapStyled>
    )
  }
}

export { DevicesMap };