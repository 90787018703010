import * as React from 'react';
import axios from 'axios';
import { Auth } from '../../services/Auth';
import { RouteComponentProps } from 'react-router';
import { SignupForm, ISignup } from './SignupForm';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';
import { ToastService } from '@independent-software/typeui/controls/Toast';
import { App } from '../../App';

interface ISignupProps {
  className?: string;
  /** Callback when signup is successful. */
  onSignup: (auth:Auth) => void;
}

interface ISignupState {
  signup: ISignup;
  isValid: boolean;
  /** Signup request in progress? Will show a loader. */
  loading: boolean;
}

class SignUp extends React.Component<ISignupProps, ISignupState> {
  constructor(props: ISignupProps & RouteComponentProps) {
    super(props);
    this.state = {
      signup: { name: '', email: '', password: '' },
      isValid: false,
      loading: false
    };
  }

  handleChange = (signup: ISignup, forceupdate: boolean) => {
    this.setState({
      signup: signup
    });
  }  

  handleValidate = (valid: boolean) => {
    this.setState({
      isValid: valid
    })
  }    

  handleSubmit = () => {
    this.setState({
      loading: true
    });
    setTimeout(() => {
      axios.post(`${App.apiURL}signup`, {
        name: this.state.signup.name,
        email: this.state.signup.email,
        password: this.state.signup.password
      })
      .then(response => {
        let auth = new Auth(
          response.data.id,
          response.data.name,
          response.data.email,
          response.data.api_token,
          response.data.rights
        );
        ToastService.toast("Sign-up successful.");
        this.props.onSignup(auth);
      })
      .catch(error => {
        let msg = '';
        if(error.response) {
          if(error.response.status == 422) {
            let firstKey = Object.keys(error.response.data)[0];
            msg = error.response.data[firstKey];
          } else {
            msg = error.response.statusText;
          }
        } else if(error.request) {
          msg = "No server response was received.";
        } else {
          msg = "There was a problem sending your request.";
        }
        ToastService.toast(<div><b>Signup failed</b><br/>{msg}</div>);
        this.setState({
          loading: false,
        });        
      });
    }, 500);
    // Adds a 500ms timeout to make sure that signup button
    // animation actually gets seen.
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <SignupForm disabled={this.state.loading} data={this.state.signup} onChange={this.handleChange} onValidate={this.handleValidate}/>
        <div style={{textAlign: 'right'}}>
          <Button primary disabled={!this.state.isValid} onClick={this.handleSubmit}>
            {this.state.loading && <Icon loading name="circle-notch"/>}
            {!this.state.loading && <span>Sign up</span>}
            {this.state.loading && <span>&nbsp;Signing up...</span>}
          </Button>
        </div>
      </div>
    )
  }
}

export { SignUp };