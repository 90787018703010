import * as React from 'react';
import { Container, Content, BottomBar, Section } from '../../modules';
import { Profile } from '../../resource/';
import { RouteComponentProps } from 'react-router';
import { ListProfiles } from './ListProfiles';
import { IAuthProps } from '../../services/Auth';

import { Button } from '@independent-software/typeui/controls/Button';
import { Icon } from '@independent-software/typeui/controls/Icon';

class ProfileList extends React.Component<IAuthProps & RouteComponentProps<any>, {}> {
  constructor(props: IAuthProps & RouteComponentProps<any>) {
    super(props);
  }

  handleAdd = () => {
    this.props.history.push('/profiles/add');
  }  

  handleClick = (item: Profile) => {
    this.props.history.push(`/profiles/${item.id}`);
  }  

  render() {
    let p = this.props;
    return (
      <Container>
        <Content>
          <ListProfiles auth={p.auth} name="profiles" onClick={this.handleClick}/>
        </Content>
        <BottomBar>
          {p.auth.hasRight('can_edit_profiles') && <Button primary onClick={this.handleAdd}><Icon name="plus"/> Add</Button>}
        </BottomBar>
      </Container>
    );
  }
}

export { ProfileList };
