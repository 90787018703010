import { LineType } from 'recharts';

interface ILineType {
  id:    LineType;
  name:  string;
}

const LineTypes: ILineType[] = [
  { id: 'linear', name: 'Linear' }, 
  { id: 'monotone', name: "Monotone" },
  { id: 'step', name: 'Stepped' }
];

export { ILineType, LineTypes }