import * as React from 'react';
import { Log, LogFactory, User, UserFactory } from '../../resource/'; 
import { IListState, List, Query, IListProps, SearchFilter } from '../../services/';
import { formatDistanceToNow, parse } from 'date-fns';
import { IconBar, Export } from '../../modules';

import { DataTable } from '@independent-software/typeui/controls/DataTable';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Form } from '@independent-software/typeui/controls/Form';
import { Gravatar } from '@independent-software/typeui/controls/Gravatar';
import { Dialog } from '@independent-software/typeui/controls/Dialog';
import { Panel } from '@independent-software/typeui/controls/Panel';
import { Input } from '@independent-software/typeui/controls/Input';

interface IListLogsProps extends IListProps {
  /**
   * If set, list is prefiltered by a user and
   * user column and filter are hidden.
   */
  userId?: number;  
}

interface IListLogsState extends IListState<Log> {
  // Data for users dropdown
  users: User[];
}

interface ILogType {
  id: string;
  title: string;
}

let logTypes: ILogType[] = [
  { id: "company", title: "Company "},
  { id: "division", title: "Division" },
  { id: "employee", title: "Employee "},
  { id: "incident", title: "Incident" },
  { id: "journey", title: "Journey" },
  { id: "pap", title: "PAP" },
  { id: "profile", title: "Profile" },
  { id: "role", title: "Role" },
  { id: "survey", title: "Survey" },
  { id: "user", title: "User" }
];

class ListLogs extends List<Log, IListLogsProps, IListLogsState> {
  constructor(props: IListLogsProps) {
    super(props, LogFactory, 'created_at', 'desc');

    // Initialize state (list initializes its own part of the state):
    this.state = {
      ...this.state,
      users: []
    };

    // The user stored in the Query is an ID, not a User.
    // Is the list prefiltered by a user? Then set a filter.
    if(this.props.userId) {
      this.setFilter('user', 'eq', { id: this.props.userId }, true);
    }    
  }

  componentDidMount() {
    // Retrieve a list of users:
    UserFactory.getSome(this.props.auth, 0, 5, new Query('name', 'asc'))
      .then((res) => this.setState({ users: res.items }));
  }

  handleSearch = (value:string) => {
    this.setFilter('q', 'like', value);
  }

  handleChangeType = (value: ILogType[]) => {
    console.log("Change type to", value);
    this.setFilter('type', 'like', value);
  }  

  handleChangeUser = (value: User) => {
    this.setFilter('user', 'eq', value);
  }  

  handleSearchUser = (q:string) => {
    // Retrieve a list of users:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    UserFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ users: res.items }));
  }  

  handleChangeFromDate = (value:string) => {
    if(value != null) value = value + " 00:00:00";
    this.setFilter('date', 'gte', value);
  }  

  handleChangeToDate = (value:string) => {
    if(value != null) value = value + " 23:59:59";
    this.setFilter('date', 'lte', value);
  }      

  render() {
    let p = this.props;

    let filter = 
    <React.Fragment>
      <Panel.Content>
        <Form.Uncontrolled hint="Type to search">
          <SearchFilter value={this.getFilter('q', 'like')} onSearch={this.handleSearch}/>
        </Form.Uncontrolled>      
        <Form.Uncontrolled hint="Filter by type">
          <Dropdown fluid clearable name="type" value={this.getFilter('type', 'like')} placeholder="Type" data={logTypes} label={(item:ILogType) => item.title} onChange={this.handleChangeType}>
            <Dropdown.Column>{(item:ILogType) => item.title}</Dropdown.Column>
          </Dropdown>
        </Form.Uncontrolled>
        {!p.userId && 
          <Form.Uncontrolled hint="Filter by user">
            <Dropdown onSearch={this.handleSearchUser} name="user" fluid clearable value={this.getFilter('user', 'eq')} data={this.state.users} placeholder="User" label={(item:User) => item.name} onChange={this.handleChangeUser}>
              <Dropdown.Column>{(item:User) => item.name}</Dropdown.Column>
            </Dropdown>
          </Form.Uncontrolled>}         
        <Form.Uncontrolled hint="Filter by start date">
          <Input name="from" type="date" fluid clearable value={this.getFilter('date', 'gte')} placeholder="From date" onChange={this.handleChangeFromDate}/>
        </Form.Uncontrolled>
        <Form.Uncontrolled hint="Filter by end date">
          <Input name="to" type="date" fluid clearable value={this.getFilter('date', 'lte')} placeholder="To date" onChange={this.handleChangeToDate}/>
        </Form.Uncontrolled>          
      </Panel.Content>
      <Panel.Footer>
        <Export onExport={this.handleExport}/>
      </Panel.Footer>      
    </React.Fragment>

    return (
      <React.Fragment>
        <IconBar>
          <Panel.Icon icon="tools" width={300}>
            {filter}
          </Panel.Icon>
        </IconBar>           
        <DataTable error={this.state.error} loading={this.state.loading} scrollTop={this.state.scrollTop} onScroll={this.handleScroll} data={this.state.items} onFetch={this.handleFetch} onOrder={this.handleOrder} order={this.getOrder()} dir={this.getDir()}>
          {!p.userId && <DataTable.Column weight={1} order="username" dir="asc" label="User">{(item:Log) => item.username ? <span><Gravatar email={item.useremail}/> {item.username}</span> : (<span style={{color:'#aaa'}}>(user deleted)</span>)}</DataTable.Column>}
          <DataTable.Column force weight={2} label="Message" order="message" dir="asc">{(item:Log) => item.message}</DataTable.Column>
          <DataTable.Column weight={1} label="Time" order="created_at" dir="desc">{(item:Log) => formatDistanceToNow(parse(item.created_at, 'yyyy-MM-dd HH:mm:ss', new Date())) + ' ago'}</DataTable.Column>
        </DataTable>
        <Dialog.Xhr open={this.state.exportError != null} error={this.state.exportError} onClose={this.handleCloseDialog}/>
      </React.Fragment>
    );
  }
}

export { ListLogs };

