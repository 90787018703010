import * as React from 'react';
import styled from '@independent-software/typeui/styles/Theme'
import { StampedModel } from '../../services/';
import { formatDistanceToNow, parse } from 'date-fns';

import { Gravatar } from '@independent-software/typeui/controls/Gravatar'
import { Icon } from '@independent-software/typeui/controls/Icon'
import { Panel } from '@independent-software/typeui/controls/Panel'
import { Flex } from '@independent-software/typeui/controls/Flex'
import { Divider } from '@independent-software/typeui/controls/Divider'


interface IProps {
  className?: string;
  model: StampedModel;
}

interface IState {
  // Panel currently open?
  open: boolean;
}

interface IStampProps {
  label: string;
  user: { name: string; email: string; };
  time: string;
}

class StampRow extends React.Component<IStampProps, {}> {
  render() {
    let p = this.props;
    return (
      <div style={{padding: '8px 16px'}}>
        <Flex.Quick>
          <Gravatar email={p.user ? p.user.email : null}/>
          <Block style={{color: p.user ? 'inherit' : '#aaa'}}>
            <Username>{p.user ? p.user.name : 'Unknown user'}</Username>
            <Time>
              {p.label} {p.time ? (formatDistanceToNow(parse(p.time, 'yyyy-MM-dd HH:mm:ss', new Date())) + ' ago') : 'at unknown time'}
            </Time>
          </Block>
        </Flex.Quick>          
      </div>
    );
  }
}

class TimestampBase extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { open: false };
  }

  handleClick = () => {
    this.setState((prevState) => { return {
      open: !prevState.open
    }});
  }

  handleClose = () => {
    this.setState({
      open: false
    });
  }

  render() {
    let p = this.props;
    return (
      <div className={p.className}>
        <Icon color="#888" size="large" name="heart-beat" onClick={this.handleClick}/>
        <Panel open={this.state.open} onClose={this.handleClose}>
          <StampRow label="Created" user={p.model.created_by} time={p.model.created_at}/>
          <Divider fitted/>
          <StampRow label="Updated" user={p.model.updated_by} time={p.model.updated_at}/>
        </Panel>
      </div>
    );
  }
}

const Username = styled('div')`
  font-weight: 500;
  white-space: nowrap;
`

const Time = styled('div')`
  font-size: 80%;
  white-space: nowrap;
`

const Block = styled('div')`
  padding-left: 10px;
`

const Timestamp = styled(TimestampBase)`
  position: relative;
  display: inline-block;  
  margin-left: 10px;
`

export { Timestamp };
