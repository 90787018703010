import * as React from 'react';
import { Country } from '../../resource/';
import { IAuthProps } from '../../services/';

import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';


interface IProps {
  /** Initial form data. */
  data: Country;
  /** Called whenever form changes. */
  onChange: (data: Country, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Country;
}

class CountryForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data
    };    
  }

  render() {
    let p = this.props;
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Flex.Columns count={2}>
          <Form.Field 
            required={{message: "Name is required"}}
            minLength={{length: 3, message: "Country name must be at least 3 characters."}}
            maxLength={{length: 255, message: "Country name may not be longer than 255 characters."}}
            width={1} label="Country name"
            name="name" 
            value={this.state.data.name}
            control={(<Input type="text" placeholder="Country name" fluid/>)}
            hint={(<React.Fragment>Please enter a country name, e.g. <code>Norway</code></React.Fragment>)}/>
          <Form.Field 
            required={{message: "Code is required"}}
            minLength={{length: 2, message: "Country code must be exactly 2 characters."}}
            maxLength={{length: 2, message: "Country code must be exactly 2 characters."}}
            width={1} label="Country code"
            name="code" 
            value={this.state.data.code}
            control={(<Input type="text" placeholder="Country code" fluid/>)}
            hint={(<React.Fragment>Please enter a country code, e.g. <code>NO</code></React.Fragment>)}/>            
        </Flex.Columns>
      </Form>
    )
  }
}

export { CountryForm };
