import * as React from 'react';
import { css } from 'styled-components';
import styled from '@independent-software/typeui/styles/Theme'
import { IMenu, ISubmenu } from '../../Types';
import { Group } from './Group';
import { IAuthProps } from '../../services/Auth';
import { RouteComponentProps } from 'react-router';

interface ISidebarProps {
  className?: string;
  /** Menu to be shown in sidebar. The menu consists of an object
   *  group keys, its values submenus which consist of keys and values.
   */
  menu: IMenu;
  /** Is sidebar forced open, even on a small screen? */
  open?: boolean;
  onClose?: any;
}

interface ISidebarState {
  // Currently active group (or null when all groups are closed).
  active: string;
}

class SidebarBase extends React.Component<IAuthProps & ISidebarProps & RouteComponentProps, ISidebarState> {
  private wrapperRef: HTMLDivElement;

  constructor(props: IAuthProps & ISidebarProps & RouteComponentProps) {
    super(props);

    let active = '';
    // Loop through menu groups.
    Object.keys(props.menu).map((key, index) => {
      let item = props.menu[key];
      // See if this menu group contains the active route by comparing
      // the current route match with each entry key.
      let containsActive = Object.keys(item).reduce((acc, key) => {
        return acc || (item[key].path === props.match.path.substr(0, item[key].path.length));
      }, false);
      // If the group contains the active route,
      // make it the initial active group.
      if(containsActive) active = key;
    });

    this.state = {
      active: active
    };
  }

  handleGroupClick = (key: string) => {
    // If clicked group is already open, close it.
    // If it is not, close old group and open new group.
    this.setState((prevState) => { return {
      active: prevState.active === key ? null : key
    };});
  }

  componentDidMount() {
    // Listen for document-wide mousedown event when Sidebar mounts.
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    // Clean up document-wide mousedown event when Sidebar unmounts.
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  
  // Handle document-wide mousedown event by closing the Sidebar if it's open.
  handleClickOutside = (event: MouseEvent) => {
    // Only close sidebar when it's open.
    if(!this.props.open) return;
    let elem:Element = event.target as Element;
    if (this.wrapperRef && !this.wrapperRef.contains(elem) && this.props.onClose) {
      this.props.onClose();
    }
  }

  render() {
    let p = this.props;
    let s = this.state;
    return (
      <div className={p.className} ref={(el:any) => this.wrapperRef = el}>
        <Header>
          <Logo><use xlinkHref={"sprites.svg#unhcr"}></use></Logo>
        </Header>
        <Menu>
          {Object.keys(p.menu).map((key, index) => {
            let item = p.menu[key];
            return <Group 
              auth={p.auth}
              key={index} 
              open={s.active === key} 
              title={key} 
              menu={item} 
              onClick={this.handleGroupClick}/>
          })}
        </Menu>
      </div>
    );
  }
}


const Logo = styled('svg')`
  width: 100%;
  height: 70px;
`

const Header = styled('div')`
  box-sizing: border-box;
  position: relative;
  max-width: 250px;
  padding: 10px;
  background-image: linear-gradient(rgb(21, 71, 121), rgb(16, 108, 200));
`

const Menu = styled('div')`
  padding: 10px 0;
  overflow-y: auto;
`

const SidebarStyled = styled(SidebarBase)`
  /* Sidebar is positioned on left side of screen: */
  position: absolute;
  z-index: 1000;
  left: 0;

  /* Top & bottom are slightly off-screen 
   * so that dropshadow corners cannot be seen. */
  top: -10px;
  bottom: -10px;
  padding: 10px 0;;

  /* Width */
  width: 250px;
  max-width: 250px;

  /* On small screens, sidebar is off the screen to the left. 
   * unless it's forced open. */
  transition: left ${p => p.theme.transition.duration}s ease;
  ${p => !p.open && css`
    @media (max-width: ${p.theme.smallScreen}px) {
      left: -250px;
    }
  `}  

  /* Colors */
  background: rgb(16, 108, 200);
  color: #fff;
  fill: #fff;

  /* Dropshadow on the right */ 
  box-shadow: 1px 0px 5px 0px rgb(0,0,0,0.75);
`

// Storybook won't find properties if we don't create a component.
class Sidebar extends React.Component<IAuthProps & ISidebarProps & RouteComponentProps, {}> {
  public static displayName = 'Sidebar';

  render() {
    return (
      <SidebarStyled {...this.props}></SidebarStyled>
    );
  }
}

export { Sidebar, ISubmenu }; 
