import * as React from 'react';
import * as L from 'leaflet';
import { Project } from '../../resource/';
import { Map } from '../../modules/';
import { CountryFactory, Country } from '../../resource';
import { IAuthProps, Query } from '../../services/';

import { MarkdownTextarea } from '@independent-software/typeui/modules/MarkdownTextarea';
import { Input } from '@independent-software/typeui/controls/Input';
import { Flex } from '@independent-software/typeui/controls/Flex';
import { Form } from '@independent-software/typeui/controls/Form';
import { Segment } from '@independent-software/typeui/controls/Segment';
import { Divider } from '@independent-software/typeui/controls/Divider';
import { Dropdown } from '@independent-software/typeui/controls/Dropdown';
import { Message } from '@independent-software/typeui/controls/Message';
import { TIMEZONES, ITimeZone } from '../../services/Timezone';
import { Checkbox } from '@independent-software/typeui/controls/Checkbox';
import { Flag } from '../../controls/Flag/Flag';


interface IProps {
  /** Initial form data. */
  data: Project;
  /** Called whenever form changes. */
  onChange: (data: Project, forceupdate: boolean) => void;
  /** Called whenever a field validates. Returns validation state for whole form. */
  onValidate: (valid: boolean) => void;
  /** 
   * Mark form as dirty. If dirty, it will show error messages for all fields,
   * even pristine fields.
   */  
  dirty?: boolean;
}

interface IState {
  /** Current form data. */
  data: Project;
  /** Data for countries dropdown */
  countries: Country[];  
}

class ProjectForm extends React.Component<IAuthProps & IProps, IState> {
  constructor(props: IAuthProps & IProps) {
    super(props);

    // Intialize state.
    this.state = {
      data: props.data,
      countries: []
    };    

    // Retrieve a list of countries:
    CountryFactory.getSome(this.props.auth, 0, 5, new Query('name', 'asc'))
      .then((res) => this.setState({ countries: res.items }));    
  }

  handleCountrySearch = (q:string) => {
    // Retrieve a list of countries:
    let query = new Query('name', 'asc');
    query.setFilter('q', 'like', q);
    CountryFactory.getSome(this.props.auth, 0, 5, query)
      .then((res) => this.setState({ countries: res.items }));
  }  

  private handleMapClick = (latlng: L.LatLng) => {
    this.state.data.latitude = latlng.lat.toString();
    this.state.data.longitude = latlng.lng.toString();
    this.props.onChange(this.state.data, true);
  }

  render() {
    let p = this.props;
    let lat = parseFloat(this.state.data.latitude); 
    let lng = parseFloat(this.state.data.longitude); 
    let latlng = (isNaN(lat) || isNaN(lng)) ? null : L.latLng(lat, lng);
    return (
      <Form
        data={this.state.data} dirty={p.dirty} onChange={p.onChange} onValidate={p.onValidate}>
        <Flex.Columns count={2}>
          <div>
            <Segment raised secondary attached="top">
              <b>Project data</b>
            </Segment>
            <Segment raised attached="bottom">            
              <Form.Field 
                required={{message: "Name is required"}}
                minLength={{length: 3, message: "Name must be at least 3 characters"}}
                maxLength={{length: 255, message: "Name may not be longer than 255 characters"}}
                width={1} label="Project name"
                name="name" 
                value={this.state.data.name}
                control={(<Input type="text" placeholder="Project name" fluid/>)}
                hint={(<React.Fragment>Please enter a project name.</React.Fragment>)}/>
              <Form.Field 
                name="public"
                value={this.state.data.public == 1}
                control={(<Checkbox label="Public" checked={this.state.data.public == 1}/>)}
                hint="Is this project visible to the public?"
                />                
              <Form.Field
                required={{message: "A country is required"}}
                width={1} label="Country"
                name="country" 
                value={this.state.data.country}
                control={(<Dropdown onSearch={this.handleCountrySearch} fluid data={this.state.countries} placeholder="Country" label={(item:Country) => item.name}>
                  <Dropdown.Column weight={1}>{(item:Country) => <span><Flag name={item.name} code={item.code}/> {item.name}</span>}</Dropdown.Column>
                </Dropdown>)}
                hint="Please select a country."/>
              <Form.Field
                required={{message: "A timezone is required"}}
                width={1} label="Timezone"
                name="timezone"
                value={this.state.data.timezone}
                control={(<Dropdown fluid data={TIMEZONES} placeholder="Timezone" label={(item:ITimeZone) => item.id + " - " + item.name}>
                  <Dropdown.Column weight={1}>{(item:ITimeZone) => item.id}</Dropdown.Column>
                  <Dropdown.Column weight={4}>{(item:ITimeZone) => item.name}</Dropdown.Column>
                </Dropdown>)}
                hint="Please select a timezone."/>

              <Flex.Columns count={2}>
                <Form.Field 
                  required={{message: "Partner is required"}}
                  width={1} label="Partner"
                  name="partner" 
                  value={this.state.data.partner}
                  control={(<Input type="text" placeholder="Partner" fluid/>)}
                  hint={(<React.Fragment>Please enter a partner name, e.g. <code>UNICEF</code>.</React.Fragment>)}/>
                <Form.Field 
                  required={{message: "Beneficiaries is required"}}
                  isInt={{message: "Beneficiaries must be a whole number."}}
                  min={{value: 0, message: "Beneficiaries must be a positive number."}}
                  width={1} label="Beneficiaries"
                  name="beneficiaries" 
                  value={this.state.data.beneficiaries}
                  control={(<Input type="text" placeholder="Beneficiaries" fluid/>)}
                  hint={(<React.Fragment>Please enter the number of beneficiaries.</React.Fragment>)}/>
              </Flex.Columns>
              <Form.Field 
                width={1} label="Project description"
                name="description" 
                value={this.state.data.description}
                control={(<MarkdownTextarea rows={10} placeholder="Description" fluid/>)}/>                  
            </Segment>
          </div>

          <div>
            <Segment raised secondary attached="top">
              <b>Project location</b>
            </Segment>
            <Segment raised attached="bottom">            
              <Map point={latlng} zoom={3} onClick={this.handleMapClick}/>
              <Divider hidden/>
              <Message type="info">
                Set a location by clicking the map, or type in a latitude/longitude below.
              </Message>            
              <Flex.Columns count={2}>
                <Form.Field 
                  forceupdate
                  required={{message: "Latitude is required"}}
                  isFloat={{message: "Latitude must be a number."}}
                  width={1} label="Latitude"
                  name="latitude" 
                  value={this.state.data.latitude}
                  control={(<Input type="text" placeholder="Latitude" fluid/>)}
                  hint={(<React.Fragment>Please enter a latitude, e.g. <code>24.3</code>.</React.Fragment>)}/>
                <Form.Field 
                  forceupdate
                  required={{message: "Longitude is required"}}
                  isFloat={{message: "Longitude must be a number."}}
                  width={1} label="Longitude"
                  name="longitude" 
                  value={this.state.data.longitude}
                  control={(<Input type="text" placeholder="Longitude" fluid/>)}
                  hint={(<React.Fragment>Please enter a longitude, e.g. <code>32.4</code>.</React.Fragment>)}/>
              </Flex.Columns>
            </Segment>
          </div>
        </Flex.Columns>
      </Form>
    )
  }
}

export { ProjectForm };
