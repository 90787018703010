import { css, createGlobalStyle } from 'styled-components'

const base = css`
  .recharts-text {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;     
  }
`;

const RechartsCustom = createGlobalStyle`
  ${base}
`;

export { RechartsCustom };
