import { StampedModel, ResourceFactory } from '../services/';
import { parse, differenceInDays } from 'date-fns';

class Device extends StampedModel {
  public eui: string = '';
  public name: string = '';
  public latitude: string = '';
  public longitude: string = '';
  public description: string = '';
  public readings_count: number = 0;
  public value: number = 0;
  public lastseen: string = '';
  public param1: number = 0;
  public param2: number = 0;
  public param3: number = 0;
  public result: number = 0;
  public maxValue: number = 0;
  public maxResult: number = 0;
  public max24h: number = 0;
  public avg24h: number = 0;
  public max30d: number = 0;
  public avg30d: number = 0;
  public battery: number = 0;
  public project: {
    id: number;
    name: string;
    public?: number;
  }
  public devicetype: {
    id: number;
    name: string;
    type: string;
  }
  
  /**
   * A device is online if its latest reading is less than 3 days ago.
   * @returns true if online
   */
  public isOnline = (): boolean => {
    if(!this.lastseen) return false;
    let diff = differenceInDays(new Date(), parse(this.lastseen.toString(), 'yyyy-MM-dd HH:mm:ss', new Date()));
    return diff <= 3;
  }
}

let DeviceFactory = ResourceFactory.create<Device>(Device, 'device');

export { Device, DeviceFactory };